import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  successDownloadBulk: false,
  loading: false,
  loadingDownloadBulk: false,
  error: '',
  errorDownloadBulk: '',
  message: '',
  qrCodeArray: [],
};

export const bulkVehicleSlice = createSlice({
  name: 'vehicle/bulkVehicle',
  initialState,
  reducers: {
    bulkVehicleRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
    },
    bulkVehicleSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload.message;
      state.loading = false;
    },
    bulkVehicleFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    bulkVehicleDefault: (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = '';
      state.message = '';
    },
    bulkDownloadVehicleRequest: (state, { payload }) => {
      state.loadingDownloadBulk = true;
      state.successDownloadBulk = false;
      state.message = '';
      state.qrCodeArray = [];
    },
    bulkDownloadVehicleSuccess: (state, { payload }) => {
      state.successDownloadBulk = true;
      state.message = payload.message;
      state.loadingDownloadBulk = false;
      state.qrCodeArray = payload.data;
    },
    bulkDownloadVehicleDefault: (state, action) => {
      state.loadingDownloadBulk = false;
      state.successDownloadBulk = false;
      state.errorDownloadBulk = '';
      state.message = '';
    },
    bulkDownloadVehicleFailure: (state, action) => {
      state.loadingDownloadBulk = false;
      state.errorDownloadBulk = action.payload;
    },
  },
});

export const {
  bulkVehicleRequest,
  bulkVehicleSuccess,
  bulkVehicleFailure,
  bulkVehicleDefault,
  bulkDownloadVehicleRequest,
  bulkDownloadVehicleSuccess,
  bulkDownloadVehicleDefault,
  bulkDownloadVehicleFailure,
} = bulkVehicleSlice.actions;

export default bulkVehicleSlice.reducer;
