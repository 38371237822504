import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  bundlesHistory: [],
  pagination: {},
  loading: false,
  error: '',
  success: false,
};

export const listBundleHistorySlice = createSlice({
  name: 'bundle/listBundleHistory',
  initialState,
  reducers: {
    getListBundleHistoryRequest: (state) => {
      state.loading = true;
      state.bundlesHistory = [];
      state.error = '';
      state.success = false;
    },
    getListBundleHistorySuccess: (state, action) => {
      state.bundlesHistory = action.payload?.responses;
      state.pagination = action.payload?.pagination;
      state.success = true;
      state.loading = false;
    },
    getListBundleHistoryFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    getListBundleHistoryDefault: (state) => {
      state.loading = false;
      state.bundlesHistory = [];
      state.error = '';
      state.success = false;
    },
  },
});

export const {
  getListBundleHistorySuccess,
  getListBundleHistoryRequest,
  getListBundleHistoryFailure,
  getListBundleHistoryDefault,
} = listBundleHistorySlice.actions;

export default listBundleHistorySlice.reducer;
