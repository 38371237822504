import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  value: {},
};

export const createRebalancingSlice = createSlice({
  name: 'systemPreference/createRebalancing',
  initialState,
  reducers: {
    createRebalancingRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.value = '';
    },
    createRebalancingSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.value = action.payload;
    },
    createRebalancingFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createRebalancingDefault: (state) => {
      state.loading = initialState?.loading;
      state.success = initialState?.success;
      state.error = initialState?.error;
      state.value = initialState?.value;
    },
  },
});

export const {
  createRebalancingDefault,
  createRebalancingFailure,
  createRebalancingRequest,
  createRebalancingSuccess,
} = createRebalancingSlice.actions;

export default createRebalancingSlice.reducer;
