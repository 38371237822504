import React, { useCallback, useEffect, useState, useRef, forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useImperativeHandle } from 'react';
import { NAME_FILTER } from 'src/constants/vehicle.constant';
import { NAME_FILTER_RIDE } from 'src/constants/ride.constant';
import { NAME_FILTER_CUSTOMER } from 'src/constants/customer.constant';

const MultiRangeSlider = forwardRef(({ min, max, onChange, title, minValLabel, maxValLabel, name, type }, ref) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const RANGE = { min: 'MIN', max: 'MAX' };
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);
  const firstRender = useRef(true);
  const getPercent = useCallback((value) => Math.round(((value - min) / (max - min)) * 100), [min, max]);
  const [selectRange, setSelectRange] = useState(RANGE.min);

  useImperativeHandle(ref, () => ({
    setDefault: () => {
      switch (name) {
        case NAME_FILTER.lastIot:
          setMinVal(0);
          setMaxVal(200);
          break;
        case NAME_FILTER.lastRide:
          setMinVal(0);
          setMaxVal(168);
          break;
        case NAME_FILTER_RIDE.feedback:
          setMinVal(0);
          setMaxVal(5);
          break;
        case NAME_FILTER_RIDE.distance:
          setMinVal(0);
          setMaxVal(10);
          break;
        case NAME_FILTER_CUSTOMER.lastRide:
          setMinVal(-1);
          setMaxVal(24);
          break;
        case NAME_FILTER_CUSTOMER.feedback:
          setMinVal(0);
          setMaxVal(5);
          break;
        case NAME_FILTER_CUSTOMER.wallet:
          setMinVal(-1);
          setMaxVal(100);
          break;
        default:
          setMinVal(0);
          setMaxVal(100);
          break;
      }
    },
  }));

  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value);
      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal]);

  return (
    <div className='relative' ref={ref}>
      <input
        type='range'
        min={min}
        max={max}
        value={minVal}
        ref={minValRef}
        onChange={(event) => {
          const value = Math.min(+event.target.value, maxVal);
          setMinVal(value);
          event.target.value = value.toString();
        }}
        onClick={() => setSelectRange(RANGE.min)}
        className={`thumb ${selectRange === RANGE.min ? 'z-[17]' : 'z-[15]'}`}
      />
      <input
        type='range'
        min={min}
        max={max}
        value={maxVal}
        ref={maxValRef}
        onChange={(event) => {
          const value = Math.max(+event.target.value, minVal);
          setMaxVal(value);
          event.target.value = value.toString();
        }}
        className={`thumb ${selectRange === RANGE.max ? 'z-[17]' : 'z-[15]'}`}
        onClick={() => setSelectRange(RANGE.max)}
      />

      <div className='slider'>
        <div className='slider__track' />
        <div ref={range} className='slider__range !z-10' />
        <div className='slider__left-value !z-10'>
          {name != null &&
          name !== NAME_FILTER_RIDE.feedback &&
          name !== NAME_FILTER_CUSTOMER.feedback &&
          name !== NAME_FILTER.lastIot &&
          name !== NAME_FILTER.lastRide
            ? minVal < 0
              ? '-'
              : minVal
            : minVal === 0
            ? '-'
            : minVal}
        </div>
        <div className='slider__right-value !z-10'>
          {maxVal === min ? (maxVal < 0 ? '-' : maxVal) : maxVal}
          {name != null &&
          name !== NAME_FILTER_RIDE.feedback &&
          name !== NAME_FILTER_CUSTOMER.feedback &&
          name !== NAME_FILTER.iotBattery &&
          name !== NAME_FILTER.vehicleBattery &&
          maxVal === max
            ? '+'
            : ''}
        </div>
      </div>
    </div>
  );
});

MultiRangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  minValLabel: PropTypes.string,
  maxValLabel: PropTypes.string,
};
MultiRangeSlider.defaultProps = {
  min: 0,
  max: 100,
  minValLabel: '0',
  maxValLabel: '100',
};

export default MultiRangeSlider;
