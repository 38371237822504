import { combineReducers } from 'redux';
import theme from './theme/themeSlice';
import auth from './auth';
import base from './base';
import vehicle from './vehicle';
import customer from './customer';
import zones from './more/zones';
import iot from './iot';
import ride from './ride';
import note from './note';
import dashboard from './dashboard';
import rideVerification from './rideVerification';
import bundle from './bundle';
import marketing from './marketing';
import appLocalization from './more/appLocalization';
import subAccount from './subAccount';
import user from './user';
import appBannerMessage from './marketing/appBannerMessage';
import advert from './marketing/advert';
import customerGroup from './marketing/customerGroup';
import pricing from './pricing';
import referralProgram from './referralProgram';
import legal from './legal';
import alertNotification from './alertNotification';
import systemPreference from './more/setting/systemPreferences';
import analytic from './analytic';
import financial from './more/financial';
import financeManagement from './financeManagement';

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    vehicle,
    zones,
    customer,
    iot,
    ride,
    note,
    dashboard,
    rideVerification,
    bundle,
    marketing,
    appLocalization,
    subAccount,
    user,
    appBannerMessage,
    pricing,
    referralProgram,
    legal,
    advert,
    financial,
    systemPreference,
    customerGroup,
    alertNotification,
    analytic,
    financeManagement,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
