import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: {},
  data: [],
};

export const getAllTypeSettingSlice = createSlice({
  name: 'settings/type-setting',
  initialState,
  reducers: {
    getAllTypeSettingRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    getAllTypeSettingSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      state.data = action.payload;
    },
    getAllTypeSettingFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAllTypeSettingDefault: (state) => {
      state.loading = initialState.loading;
      state.success = initialState.success;
      state.error = initialState.error;
      state.message = initialState.message;
    },
  },
});

export const {
  getAllTypeSettingRequest,
  getAllTypeSettingSuccess,
  getAllTypeSettingFailure,
  getAllTypeSettingDefault,
} = getAllTypeSettingSlice.actions;

export default getAllTypeSettingSlice.reducer;
