import React, { forwardRef, useMemo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination, Select, Checkbox, Card } from 'components/ui';
import TableRowSkeleton from './loaders/TableRowSkeleton';
import Loading from './Loading';
import { useTable, usePagination, useSortBy, useRowSelect } from 'react-table';
import { Table } from '../ui';
import { useDispatch } from 'react-redux';
import { setSort } from 'src/store/base/sortSlice';

const { Tr, Th, Td, THead, TBody, Sorter } = Table;

const IndeterminateCheckbox = forwardRef((props, ref) => {
  const { indeterminate, onChange, onCheckBoxChange, onIndeterminateCheckBoxChange, ...rest } = props;

  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const handleChange = (e) => {
    onChange(e);
    onCheckBoxChange?.(e);
    onIndeterminateCheckBoxChange?.(e);
  };

  return <Checkbox className='mb-0' ref={resolvedRef} onChange={(_, e) => handleChange(e)} {...rest} />;
});

const DataTable = (props) => {
  const {
    skeletonAvatarColumns,
    columns,
    data,
    loading,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    onPaginationChange,
    onSelectChange,
    onSort,
    pageSizes,
    selectable,
    skeletonAvatarProps,
    pagingData,
    autoResetSelectedRows,
    hasPaging,
    hasHead,
    oveerflow,
    isPagination,
    className,
    onRowClick,
  } = props;

  const { pageSize, pageIndex, total, sort } = pagingData;
  const dispatch = useDispatch();

  const pageSizeOption = useMemo(
    () => pageSizes.map((number) => ({ value: number, label: `${number} / page` })),
    [pageSizes]
  );

  const handleCheckBoxChange = (checked, row) => {
    if (!loading) {
      onCheckBoxChange?.(checked, row);
    }
  };

  const handleIndeterminateCheckBoxChange = (checked, rows) => {
    if (!loading) {
      onIndeterminateCheckBoxChange?.(checked, rows);
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: sort?.current || [],
      },
      manualPagination: true,
      manualSortBy: true,
      autoResetSelectedRows,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (selectable) {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: (props) => (
              <div>
                <IndeterminateCheckbox
                  {...props.getToggleAllRowsSelectedProps()}
                  onIndeterminateCheckBoxChange={(e) => handleIndeterminateCheckBoxChange(e.target.checked, props.rows)}
                />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  onCheckBoxChange={(e) => handleCheckBoxChange(e.target.checked, row.original)}
                />
              </div>
            ),
            sortable: false,
          },
          ...columns,
        ]);
      }
    }
  );

  const handlePaginationChange = (page) => {
    if (!loading) {
      onPaginationChange?.(page);
    }
  };

  const handleSelectChange = (value) => {
    if (!loading) {
      onSelectChange?.(Number(value));
    }
  };

  const handleSort = async (column) => {
    if (!loading) {
      const { id, isSortedDesc, toggleSortBy, clearSortBy } = column;
      sort.current = [{ id: id, desc: !isSortedDesc }];
      const sortOrder = isSortedDesc ? 'desc' : 'asc';
      dispatch(setSort({ order: sortOrder, key: id }));
      // toggleSortBy(!isSortedDesc);
      // await onSort?.({ order: sortOrder, key: id }, { id, clearSortBy });
    }
  };

  return (
    <Loading loading={loading && data.length !== 0} type='cover'>
      <Card headerBorder={false}>
        <Table {...getTableProps()} data={data} oveerflow={oveerflow} className={className}>
          {hasHead && (
            <THead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th {...column.getHeaderProps()}>
                      {column.render('Header') &&
                        (column.sortable ? (
                          <div className='cursor-pointer' onClick={() => handleSort(column)}>
                            {column.render('Header')}
                            <span>
                              <Sorter sort={column.isSortedDesc} />
                            </span>
                          </div>
                        ) : (
                          <div>{column.render('Header')}</div>
                        ))}
                    </Th>
                  ))}
                </Tr>
              ))}
            </THead>
          )}
          {loading && data.length === 0 ? (
            <TableRowSkeleton
              columns={columns.length}
              rows={pagingData.pageSize}
              avatarInColumns={skeletonAvatarColumns}
              avatarProps={skeletonAvatarProps}
            />
          ) : (
            <TBody {...getTableBodyProps()} className='bg-white dark:bg-gray-800'>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    onClick={() => {
                      onRowClick && onRowClick(row);
                    }}
                    className={`${onRowClick && 'cursor-pointer'} hover:text-amber-500 relative hover:bg-white`}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <Td {...cell.getCellProps()} className='!bg-white dark:!bg-gray-800'>
                          {cell.render('Cell')}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </TBody>
          )}
        </Table>
      </Card>

      {data.length > 0 ? (
        <div className='flex items-center justify-between mt-4'>
          {isPagination ? (
            ''
          ) : (
            <Pagination pageSize={pageSize} currentPage={pageIndex} total={total} onChange={handlePaginationChange} />
          )}
          {hasPaging && (
            <div style={{ minWidth: 130 }}>
              <Select
                size='sm'
                menuPlacement='top'
                isSearchable={false}
                value={pageSizeOption.filter((option) => option.value === pageSize)}
                options={pageSizeOption}
                onChange={(option) => handleSelectChange(option.value)}
              />
            </div>
          )}
        </div>
      ) : (
        <Card className='border-none w-full h-20 flex justify-center items-center'>No record found</Card>
      )}
    </Loading>
  );
};

DataTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  onCheckBoxChange: PropTypes.func,
  onIndeterminateCheckBoxChange: PropTypes.func,
  onPaginationChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  onSort: PropTypes.func,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  selectable: PropTypes.bool,
  skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
  skeletonAvatarProps: PropTypes.object,
  pagingData: PropTypes.shape({
    total: PropTypes.number,
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
  }),
  autoResetSelectedRows: PropTypes.bool,
  hasPaging: PropTypes.bool,
  hasHead: PropTypes.bool,
};

DataTable.defaultProps = {
  pageSizes: [10, 25, 50, 100],
  pagingData: {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
  },
  data: [],
  columns: [],
  selectable: false,
  loading: false,
  autoResetSelectedRows: true,
  hasPaging: true,
  hasHead: true,
};

export default DataTable;
