import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  message: '',
  loading: false,
  error: '',
  success: false,
  createdAt: '',
};

export const createNoteForVehicleDetailSlice = createSlice({
  name: 'vehicle/createNoteForVehicleDetail',
  initialState,
  reducers: {
    createNoteForVehicleDetailRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
      state.error = '';
      state.createdAt = '';
    },
    createNoteForVehicleDetailSuccess: (state, { payload }) => {
      state.success = true;
      state.loading = false;
      state.message = 'Create note is successfully!';
      state.createdAt = payload?.createdAt;
    },
    createNoteForVehicleDetailFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createNoteForVehicleDetailDefault: (state) => {
      state.success = false;
      state.loading = false;
      state.error = '';
      state.message = '';
      state.createdAt = '';
    },
  },
});

export const {
  createNoteForVehicleDetailRequest,
  createNoteForVehicleDetailSuccess,
  createNoteForVehicleDetailFailure,
  createNoteForVehicleDetailDefault,
} = createNoteForVehicleDetailSlice.actions;

export default createNoteForVehicleDetailSlice.reducer;
