import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  iots: [],
  loading: false,
  error: '',
  success: false,
};

export const allIoTSlice = createSlice({
  name: 'iot/allIoT',
  initialState,
  reducers: {
    getAllIoTRequest: (state) => {
      state.loading = true;
      state.iots = [];
      state.error = '';
      state.success = false;
    },
    getAllIoTSuccess: (state, action) => {
      state.iots = action.payload;
      state.success = true;
      state.loading = false;
    },
    getAllIoTFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    getAllIoTDefault: (state) => {
      state.loading = false;
      state.iots = [];
      state.error = '';
      state.success = false;
    },
  },
});

export const { getAllIoTSuccess, getAllIoTRequest, getAllIoTFailure, getAllIoTDefault } = allIoTSlice.actions;

export default allIoTSlice.reducer;
