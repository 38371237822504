import {
  DrawingManager,
  GoogleMap,
  InfoWindow,
  Marker,
  Polygon,
  Polyline,
  useJsApiLoader,
} from '@react-google-maps/api';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { TbMap2 } from 'react-icons/tb';
import { TiArrowBack } from 'react-icons/ti';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useDispatch, useSelector } from 'react-redux';
import rideVerificationTest from 'src/assets/imgs/ic_ride_verification_test.png';

import {
  TOAST_DANGER,
  TOAST_MSG_UPDATE,
  TOAST_SUCCESS,
  TOAST_TITLE_VEHICLE_HISTORY,
} from 'src/constants/toast.constant';
import { bulkVehicleDefault } from 'src/store/vehicle/bulkVehicleSlice';
import { getFullDate, getFullTime, getTimeAgo, openNotification } from 'src/utils/helper';
import useVehicle from 'src/utils/hooks/useVehicle';
import useZones from 'src/utils/hooks/useZones';
import Select from '../Select';
import { PLACE_HOLDER_IMG } from '../utils/constant';
import './index.css';
import iconCircle from '/assets/imgs/ic-circle.png';
import iconVehicle from '/assets/imgs/ic-vehicle.png';

const GeoMaps = forwardRef((props, ref) => {
  const {
    height,
    borderRadius,
    color,
    paths = [],
    point,
    width,
    isAdd,
    isEditPolygon,
    id,
    allPolygon,
    isShowAllPolygon,
    latestCoordinates,
    loading,
    icon,
    zoom,
    hasMarker,
    hasInfoMarker,
    vehicle,
    status,
    defaultPaths,
    historyPath,
    latestPosition,
    isHistory = false,
  } = props;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAP,
    libraries: ['drawing'],
  });
  const [pathBefore, setPathBefore] = useState(paths);
  const [newPath, setNewPath] = useState({});
  const [gps, setGps] = useState('LOCATION');
  const [historyLocation, setHistoryLocation] = useState({});
  const [showInfoHistory, setShowInfoHistory] = useState(false);
  const [visible, setVisible] = useState(false);
  const { bulkMultipleVehicle } = useVehicle();
  // const { lat, lng } = useSelector((state) => state.auth.admin);
  const geolocationAPI = navigator.geolocation;
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [statusVehicle, setStatusVehicle] = useState({});
  const [value, setValue] = useState();
  const mapRender = useRef();
  useEffect(() => {
    geolocationAPI.getCurrentPosition((position) => {
      const { coords } = position;

      setLocation(
        latestPosition?.lat
          ? { lat: parseFloat(latestPosition?.lat), lng: parseFloat(latestPosition?.lng) }
          : { lat: coords.latitude, lng: coords.longitude }
      );
    });
  }, [geolocationAPI, latestPosition]);

  useEffect(() => {
    setStatusVehicle(vehicle?.status ? status?.find((item) => item?.value === vehicle?.status) : {});
  }, [vehicle]);

  const { postNewPolygon } = useZones();

  const createNewPolygon = async (values) => {
    await postNewPolygon(values);
  };

  const options = {
    drawingControl: true,
    drawingControlOptions: {
      drawingMode: ['Polygon'],
    },
    polygonOptions: {
      fillColor: color,
      strokeColor: color,
      fillOpacity: 0.35,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zindex: 1,
    },
  };

  const onPolygonComplete = useCallback(
    function onPolygonComplete(poly) {
      const polyArray = poly.getPath().getArray();
      let paths = [];
      polyArray.forEach(function (path) {
        paths.push({ lat: path.lat(), lng: path.lng() });
      });
      createNewPolygon({ type: 'Polygon', coordinates: paths, zoneId: id });
      point(paths);
      poly.setMap(null);
    },
    [point]
  );

  const polygonRef = useRef(null);
  const listenerRef = useRef([]);

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latlng) => {
          return { lat: latlng.lat(), lng: latlng.lng() };
        });
      point(nextPath);
      setPathBefore(paths);
    }
  }, [point]);

  useEffect(() => {
    let map = [];
    paths.map((item) => {
      const path = pathBefore.find((data) => {
        return data.lat === item.lat && data.lng === item.lng;
      });
      if (!path) {
        map.push(item);
      }
      return item;
    });
    setNewPath(map ? map[0] : {});
  }, [pathBefore, paths]);

  const onLoad = useCallback(
    function callback(polygon) {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenerRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit)
      );
    },
    [onEdit]
  );

  const onLoadGoogle = React.useCallback(
    function callback(map) {
      mapRender.current = map;
      var bounds = new window.google.maps.LatLngBounds();

      const polygonCoords = paths.map((p) => {
        return new window.google.maps.LatLng(p.lat, p.lng);
      });

      for (var i = 0; i < polygonCoords?.length; i++) {
        bounds?.extend(polygonCoords[i]);
      }
      paths.length > 0 && map.fitBounds(bounds);
    },
    [geolocationAPI, paths]
  );

  const onUnmount = useCallback(() => {
    listenerRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const { success: successBulk, message, error } = useSelector((state) => state.vehicle.bulkVehicle);

  const dispatch = useDispatch();
  const { getVehicleById } = useVehicle();
  useEffect(() => {
    if (successBulk) {
      openNotification(TOAST_TITLE_VEHICLE_HISTORY + TOAST_MSG_UPDATE, TOAST_SUCCESS);
      dispatch(bulkVehicleDefault());
      const getVehicle = async () => {
        await getVehicleById({ id: vehicle?.vehicleId });
      };
      getVehicle();
      setVisible(false);
    }
    if (error) {
      openNotification(message, TOAST_DANGER);
    }
  }, [successBulk, error]);

  useImperativeHandle(ref, () => ({
    showGPS: () => {
      setGps('GPS');
    },
    fitLocation: () => {
      setGps('LOCATION');
      onLoadGoogle(mapRender.current);
      // setValue({});
      setValue({});
    },
    setNewPathAfterUpdatePolygon: () => {
      setNewPath(null);
    },
  }));

  return (
    <div ref={ref}>
      {isLoaded && !loading ? (
        <GoogleMap
          mapContainerStyle={{
            height: `${height}`,
            width: `${width}`,
            borderRadius: `${borderRadius}`,
            minHeight: `${height}`,
          }}
          center={gps === 'GPS' || paths.length === 0 ? location : null}
          zoom={zoom || ((gps === 'GPS' || paths.length === 0) && 10) || null}
          onLoad={(gps === 'LOCATION' || paths.length > 0) && ((map) => onLoadGoogle(map))}
        >
          {!isHistory && (
            <>
              {paths.length === 0 || paths.length === 1 || paths.length === 2 ? (
                isAdd && (
                  <>
                    <DrawingManager
                      drawingMode={'polygon'}
                      options={options}
                      editable={isAdd}
                      draggable
                      onPolygonComplete={onPolygonComplete}
                      onMouseUp={onEdit}
                      onDragEnd={onEdit}
                    />
                    {allPolygon?.map((item, index) => (
                      <Polygon
                        key={index}
                        options={{
                          fillColor: item?.color,
                          strokeColor: item?.color,
                          fillOpacity: 0.35,
                          strokeWeight: 2,
                        }}
                        path={item?.coordinates}
                      />
                    ))}
                  </>
                )
              ) : (
                <>
                  <Polygon
                    options={{
                      fillColor: color,
                      strokeColor: color,
                      fillOpacity: 0.35,
                      strokeWeight: 2,
                    }}
                    editable={isEditPolygon}
                    path={paths}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    onMouseUp={onEdit}
                    onDragEnd={onEdit}
                  />
                  {isEditPolygon &&
                    allPolygon?.map((item, index) => (
                      <Polygon
                        key={index}
                        options={{
                          fillColor: item?.color,
                          strokeColor: item?.color,
                          fillOpacity: 0.35,
                          strokeWeight: 2,
                        }}
                        path={item?.coordinates}
                      />
                    ))}
                </>
              )}
            </>
          )}

          {isShowAllPolygon &&
            allPolygon.length > 0 &&
            allPolygon?.map((item, index) => (
              <Polygon
                key={index}
                options={{
                  fillColor: item?.color,
                  strokeColor: item?.color,
                  fillOpacity: 0.35,
                  strokeWeight: 2,
                }}
                path={item?.coordinates}
              />
            ))}
          {hasMarker && (
            <Marker
              position={location}
              onClick={() => {
                setVisible(true);
              }}
              icon={{
                url: iconVehicle,
                scaledSize: new window.google.maps.Size(40, 40),
              }}
              className='w-[20px] h-[20px]'
            />
          )}

          {hasInfoMarker && visible && (
            <InfoWindow
              position={location}
              onCloseClick={() => {
                setVisible(false);
              }}
            >
              <div className='flex flex-col'>
                <h6 className='font-light text-green-600'>{`Vehicle #${
                  vehicle?.vehicleNo ? vehicle?.vehicleNo : ''
                }`}</h6>
                <div className='grid w-full grid-cols-2 gap-4 my-4 '>
                  <div className='w-[120px] h-[180px] map'>
                    <LazyLoadImage
                      alt={`sicImage`}
                      src={vehicle?.parkingPhoto || PLACE_HOLDER_IMG}
                      effect='blur'
                      placeholderSrc={vehicle?.parkingPhoto || PLACE_HOLDER_IMG}
                      className='h-full object-cover w-full'
                    />
                  </div>
                  <div className='col-span-1 text-base'>
                    <div className='mb-2'>{`Battery:${
                      vehicle?.vehicleBattery ? vehicle?.vehicleBattery + '%' : '?'
                    }`}</div>
                    <div className='mb-2'>{`IoT:${vehicle?.iotBattery ? vehicle?.iotBattery + '%' : '?'}`}</div>
                    <div className='mb-2'>{`Last updated:${
                      latestCoordinates?.createdAt ? getTimeAgo(latestCoordinates?.createdAt) : '?'
                    }`}</div>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${
                        vehicle?.lat ? vehicle?.lat : 42.6441333
                      },${vehicle?.lng ? vehicle?.lng : 18.1146}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <TbMap2 className='w-6 h-6 mr-2 cursor-pointer' />
                    </a>
                  </div>
                </div>
                <Select
                  className='min-w-[120px] w-[calc(100%-2px)] mx-[1px] mb-[1px]'
                  options={status}
                  value={statusVehicle}
                  size='sm'
                  // menuPlacement='auto'
                  // menuPosition='fixed'
                  onChange={async (option) => {
                    setStatusVehicle(option);
                    await bulkMultipleVehicle({
                      ids: [vehicle?.vehicleId],
                      type: 'STATUS',
                      value: option.value,
                    });
                  }}
                />
              </div>
            </InfoWindow>
          )}
          {isEditPolygon && newPath && (
            <InfoWindow position={newPath}>
              <TiArrowBack
                className='w-[20px] h-[20px] '
                onClick={() => {
                  point(pathBefore);
                }}
              />
            </InfoWindow>
          )}

          {historyPath && (
            <>
              <Polyline path={historyPath} strokeColor='#0000FF' strokeOpacity={0.8} strokeWeight={2} />
              {historyPath?.map((h, index) => {
                return (
                  <Marker
                    key={index}
                    position={h}
                    icon={{
                      url: iconCircle,
                      fillColor: '#2ad586',
                      fillOpacity: 1,
                      scaledSize: new window.google.maps.Size(15, 15),
                    }}
                    onClick={() => {
                      setHistoryLocation(h);
                      setShowInfoHistory(true);
                    }}
                  />
                );
              })}
              {showInfoHistory && historyLocation && (
                <InfoWindow
                  position={historyLocation}
                  onCloseClick={() => {
                    setShowInfoHistory(false);
                  }}
                >
                  <div className='flex flex-col w-[260px] pb-5'>
                    <div className='col-span-1 text-base'>
                      <div className='mb-2'>{`Date:  ${
                        historyLocation?.createdAt ? getFullDate(historyLocation?.createdAt) : '?'
                      }`}</div>
                      <div className='mb-2'>{`Time:  ${
                        historyLocation?.createdAt ? getFullTime(historyLocation?.createdAt) : '?'
                      }`}</div>
                      <div className='mb-2'>{`Latitude:  ${historyLocation?.lat ? historyLocation?.lat : '?'}`}</div>
                      <div className='mb-2'>{`Longitude:  ${historyLocation?.lng ? historyLocation?.lng : '?'}`}</div>
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${
                          historyLocation?.lat ? historyLocation?.lat : 42.6441333
                        },${historyLocation?.lng ? historyLocation?.lng : 18.1146}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <TbMap2 className='w-6 h-6 mr-2 cursor-pointer' />
                      </a>
                    </div>
                  </div>
                </InfoWindow>
              )}
            </>
          )}
        </GoogleMap>
      ) : (
        <div className='w-full bg-gray-300' style={{ height: height }}></div>
      )}
    </div>
  );
});

export default React.memo(GeoMaps);
