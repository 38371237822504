import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
};

export const createVehicleSlice = createSlice({
  name: 'vehicle/createVehicle',
  initialState,
  reducers: {
    onCreateVehicleRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
    },
    onCreateVehicleSuccess: (state) => {
      state.success = true;
      state.loading = false;
    },
    onCreateVehicleFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    onCreateVehicleDefault: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = '';
    },
  },
});

export const { onCreateVehicleRequest, onCreateVehicleSuccess, onCreateVehicleFailure, onCreateVehicleDefault } =
  createVehicleSlice.actions;

export default createVehicleSlice.reducer;
