import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const deleteCustomerByIdSlice = createSlice({
  name: 'customer/deleteCustomerById',
  initialState,
  reducers: {
    deleteCustomerByIdRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
      state.error = '';
    },
    deleteCustomerByIdSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload;
      state.loading = false;
    },
    deleteCustomerByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteCustomerByIdDefault: (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const {
  deleteCustomerByIdRequest,
  deleteCustomerByIdSuccess,
  deleteCustomerByIdFailure,
  deleteCustomerByIdDefault,
} = deleteCustomerByIdSlice.actions;

export default deleteCustomerByIdSlice.reducer;
