import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const createCustomerGroupSlice = createSlice({
  name: 'customerGroup/createCustomerGroup',
  initialState,
  reducers: {
    createCustomerGroupRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    createCustomerGroupSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload;
      state.loading = false;
    },
    createCustomerGroupFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createCustomerGroupDefault: (state) => {
      state.loading = false;
      state.success = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const {
  createCustomerGroupRequest,
  createCustomerGroupSuccess,
  createCustomerGroupFailure,
  createCustomerGroupDefault,
} = createCustomerGroupSlice.actions;

export default createCustomerGroupSlice.reducer;
