import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  iotDataLog: [],
  pagination: {},
  loading: false,
  error: '',
  success: false,
};

export const listIoTDataLog = createSlice({
  name: 'iot/listIoTDataLog',
  initialState,
  reducers: {
    getAllIoTDataLogRequest: (state) => {
      state.loading = true;
      state.iotDataLog = [];
      state.error = '';
      state.success = false;
    },
    getAllIoTDataLogSuccess: (state, action) => {
      state.iotDataLog = action.payload?.responses;
      state.pagination = action.payload?.pagination;
      state.success = true;
      state.loading = false;
    },
    getAllIoTDataLogFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    getAllIoTDataLogDefault: (state) => {
      state.loading = false;
      state.error = '';
      state.success = false;
    },
  },
});

export const { getAllIoTDataLogSuccess, getAllIoTDataLogRequest, getAllIoTDataLogFailure, getAllIoTDataLogDefault } =
  listIoTDataLog.actions;

export default listIoTDataLog.reducer;
