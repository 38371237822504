import { combineReducers } from '@reduxjs/toolkit';
import getAllTypeSetting from './getAllTypeSettingSlice';
import getData from './getDataSlice';
import createData from './createDataSlice';

const reducer = combineReducers({
  getAllTypeSetting,
  getData,
  createData,
});

export default reducer;
