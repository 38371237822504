import { combineReducers } from '@reduxjs/toolkit';
import session from './sessionSlice';
import user from './userSlice';
import admin from './adminSlice';
import subAccountSwitch from './subAccountSwitchSlice';
import listUser from './listUserSlice';
import createUser from './createUserSlice';
import updateUser from './updateUserSlice';
import deleteUserById from './deleteUserByIdSlice';
import refreshToken from './refreshTokenSlice';
import listEmployee from './getEmployeeActivity';

const reducer = combineReducers({
  session,
  user,
  admin,
  subAccountSwitch,
  listUser,
  createUser,
  updateUser,
  deleteUserById,
  refreshToken,
  listEmployee,
});

export default reducer;
