import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: {},
};

export const createAdvertSlice = createSlice({
  name: 'Create Advert',
  initialState,
  reducers: {
    createAdvertRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    createAdvertSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
    },
    createAdvertFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createAdvertDefault: (state) => {
      state.loading = initialState?.loading;
      state.success = initialState?.success;
      state.error = initialState?.error;
      state.message = initialState?.message;
    },
  },
});

export const { createAdvertRequest, createAdvertSuccess, createAdvertFailure, createAdvertDefault } =
  createAdvertSlice.actions;

export default createAdvertSlice.reducer;
