import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  vehicles: [],
  pagination: {},
  loading: false,
  error: '',
};

export const listVehicleSlice = createSlice({
  name: 'vehicle/listVehicle',
  initialState,
  reducers: {
    onGetListVehicleRequest: (state) => {
      state.loading = true;
    },
    onGetListVehicleSuccess: (state, action) => {
      state.vehicles = action.payload?.responses;
      state.pagination = action.payload?.pagination;
      state.loading = false;
    },
    onGetListVehicleFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { onGetListVehicleSuccess, onGetListVehicleRequest, onGetListVehicleFailure } = listVehicleSlice.actions;

export default listVehicleSlice.reducer;
