import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  ride: [],
  pagination: {},
  loading: false,
  error: '',
};

export const listRideSlice = createSlice({
  name: 'ride/listRide',
  initialState,
  reducers: {
    getListRideRequest: (state) => {
      state.loading = true;
    },
    getListRideSuccess: (state, action) => {
      state.ride = action.payload?.responses;
      state.pagination = action.payload?.pagination;
      state.loading = false;
    },
    getListRideFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getListRideRequest, getListRideSuccess, getListRideFailure } = listRideSlice.actions;

export default listRideSlice.reducer;
