import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  listType: [],
  error: '',
};

export const listCustomerTypeSlice = createSlice({
  name: 'customer/listCustomerType',
  initialState,
  reducers: {
    getListCustomerTypeRequest: (state) => {},
    getListCustomerTypeSuccess: (state, action) => {
      state.listType = action.payload;
      state.error = '';
    },
    getListCustomerTypeFailure: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { getListCustomerTypeRequest, getListCustomerTypeSuccess, getListCustomerTypeFailure } =
  listCustomerTypeSlice.actions;

export default listCustomerTypeSlice.reducer;
