import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  type: '',
};

export const createDataSlice = createSlice({
  name: 'createData',
  initialState,
  reducers: {
    createDataRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.type = '';
    },
    createDataSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.type = action.payload;
    },
    createDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createDataDefault: (state) => {
      state.loading = initialState?.loading;
      state.success = initialState?.success;
      state.error = initialState?.error;
      state.type = initialState?.type;
    },
  },
});

export const { createDataRequest, createDataSuccess, createDataFailure, createDataDefault } = createDataSlice.actions;

export default createDataSlice.reducer;
