import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  pagination: {},
  error: '',
  message: {},
  listSub: [],
};

export const getSubConfigSlice = createSlice({
  name: 'financeManagement/getSubConfig',
  initialState,
  reducers: {
    getSubConfigRequest: (state) => {
      state.loading = true;
    },
    getSubConfigSuccess: (state, action) => {
      state.loading = false;
      state.pagination = action.payload?.pagination;
      state.listSub = action.payload?.responses;
    },
    getSubConfigFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getSubConfigFailure, getSubConfigRequest, getSubConfigSuccess } = getSubConfigSlice.actions;

export default getSubConfigSlice.reducer;
