import { combineReducers } from '@reduxjs/toolkit';
import createZone from './createZoneSlice';
import listZone from './listZoneSlice';
import createPolygon from './createPolygonSlice';
import zoneById from './zoneByIdSlice';
import updateZone from './updateZoneSlice';
import deleteZone from './deleteZoneSlice';
import deletePolygon from './deletePolygonSlice';
import listPolygon from './listPolygonSlice';
import updatePolygon from './updatePolygonSlice';
import updateDesc from './updateDescZoneSlice';
const reducer = combineReducers({
  createZone,
  listZone,
  createPolygon,
  zoneById,
  updateZone,
  deleteZone,
  deletePolygon,
  listPolygon,
  updatePolygon,
  updateDesc,
});

export default reducer;
