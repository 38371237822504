import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
};

export const createZoneSlice = createSlice({
  name: 'zones/createZone',
  initialState,
  reducers: {
    onCreateZoneRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
    },
    onCreateZoneSuccess: (state) => {
      state.success = true;
      state.loading = false;
    },
    onCreateZoneFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    onCreateZoneDefault: (state) => {
      state.loading = false;
      state.success = false;
      state.error = '';
    },
  },
});

export const { onCreateZoneRequest, onCreateZoneSuccess, onCreateZoneFailure, onCreateZoneDefault } =
  createZoneSlice.actions;

export default createZoneSlice.reducer;
