import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: '',
};

export const updateDescZoneSlice = createSlice({
  name: 'zones/updateDescZone',
  initialState,
  reducers: {
    updateDescZoneRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    updateDescZoneSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
    },
    updateDescZoneFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateDescZoneDefault: (state) => {
      state.loading = false;
      state.error = '';
      state.success = false;
      state.message = '';
    },
  },
});

export const { updateDescZoneDefault, updateDescZoneFailure, updateDescZoneRequest, updateDescZoneSuccess } =
  updateDescZoneSlice.actions;

export default updateDescZoneSlice.reducer;
