import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  polygons: [],
  loading: false,
  error: '',
  pagination: {},
};

export const listPolygonSlice = createSlice({
  name: 'zones/listPolygon',
  initialState,
  reducers: {
    getListPolygonRequest: (state) => {
      state.loading = true;
      state.polygons = [];
      state.pagination = {};
      state.error = '';
    },
    getListPolygonSuccess: (state, action) => {
      state.polygons = action.payload;
      state.loading = false;
    },
    getListPolygonFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getListPolygonDefault: (state) => {
      state.loading = true;
      state.polygons = [];
      state.pagination = {};
      state.error = '';
    },
  },
});

export const { getListPolygonRequest, getListPolygonSuccess, getListPolygonFailure, getListPolygonDefault } =
  listPolygonSlice.actions;

export default listPolygonSlice.reducer;
