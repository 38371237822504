import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const deleteDocumentSlice = createSlice({
  name: 'vehicle/deleteDocument',
  initialState,
  reducers: {
    deleteDocumentRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
      state.error = '';
    },
    deleteDocumentSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload;
      state.loading = false;
    },
    deleteDocumentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteDocumentDefault: (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const { deleteDocumentDefault, deleteDocumentFailure, deleteDocumentRequest, deleteDocumentSuccess } =
  deleteDocumentSlice.actions;

export default deleteDocumentSlice.reducer;
