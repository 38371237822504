import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  options: [],
  loading: false,
  success: true,
  error: '',
};

export const getOptionsBundleSlice = createSlice({
  name: 'bundle/options',
  initialState,
  reducers: {
    getOptionsBundleRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.bundle = [];
      state.error = '';
    },
    getOptionsBundleSuccess: (state, action) => {
      state.loading = false;
      state.options = action.payload;
      state.success = true;
    },
    getOptionsBundleFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getOptionsBundleDefault: (state) => {
      state.loading = false;
      state.options = [];
      state.error = '';
      state.success = false;
    },
  },
});

export const { getOptionsBundleRequest, getOptionsBundleSuccess, getOptionsBundleFailure, getOptionsBundleDefault } =
  getOptionsBundleSlice.actions;

export default getOptionsBundleSlice.reducer;
