import { combineReducers } from '@reduxjs/toolkit';
import listVehicle from './listVehicleSlice';
import createVehicle from './createVehicleSlice';
import listVehicleSelected from './listVehicleSelectedSlice';
import listVehicleType from './listVehicleTypeSlice';
import bulkVehicle from './bulkVehicleSlice';
import deleteVehicleById from './deleteVehicleByIdSlice';
import getVehicleById from './getVehicleByIdSlice';
import updateVehicleById from './updateVehicleByIdSlice';
import listVehicleActivity from './getVehicleActivitySlice';
import getNotesForVehicleDetail from './getNotesForVehicleDetailSlice';
import createNoteForVehicleDetail from './createNoteForVehicleDetailSlice';
import downloadQrVehicleById from './downloadQrVehicleByIdSlice';
import importVehicle from './importVehicleSlice';
import historyVehicle from './historyVehicleSlice';
import uploadDocumentVehicle from './uploadDocumentVehicleSlice';
import getDocumentVehicle from './getDocumentVehicleSlice';
import callCommand from './callCommandVehicleSlice';
import deleteDocument from './deleteDocumentSlice';
const reducer = combineReducers({
  listVehicle,
  createVehicle,
  listVehicleSelected,
  listVehicleType,
  bulkVehicle,
  deleteVehicleById,
  getVehicleById,
  updateVehicleById,
  listVehicleActivity,
  getNotesForVehicleDetail,
  createNoteForVehicleDetail,
  downloadQrVehicleById,
  importVehicle,
  historyVehicle,
  uploadDocumentVehicle,
  getDocumentVehicle,
  callCommand,
  deleteDocument,
});

export default reducer;
