import { createSlice } from '@reduxjs/toolkit';

export const refreshTokenSlice = createSlice({
  name: 'auth/refreshToken',
  initialState: {
    token: '',
    error: '',
    loading: false,
    success: false,
  },
  reducers: {
    refreshTokenRequest: (state) => {
      state.token = '';
      state.loading = true;
      state.success = false;
      state.error = '';
    },
    refreshTokenSuccess: (state, action) => {
      state.token = action.payload;
      state.loading = false;
      state.success = true;
    },
    refreshTokenFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    refreshTokenDefault: (state, action) => {
      state.token = '';
      state.loading = false;
      state.success = false;
      state.error = '';
    },
  },
});

export const { refreshTokenRequest, refreshTokenSuccess, refreshTokenFailure, refreshTokenDefault } =
  refreshTokenSlice.actions;

export default refreshTokenSlice.reducer;
