import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  sort: { key: 'id', order: 'desc' },
};

export const sortSlice = createSlice({
  name: 'base/sort',
  initialState,
  reducers: {
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setCurrentSort: (state) => {
      state.sort = initialState.sort;
    },
  },
});

export const { setCurrentSort, setSort } = sortSlice.actions;

export default sortSlice.reducer;
