import { combineReducers } from '@reduxjs/toolkit';
import createBilling from './createBilling';
import getBilling from './getBilling';

const reducer = combineReducers({
  createBilling,
  getBilling,
});

export default reducer;
