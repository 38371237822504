import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: {},
};

export const updateCustomerGroupSlice = createSlice({
  name: 'customerGroup/updateCustomerGroup',
  initialState,
  reducers: {
    updateCustomerGroupRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    updateCustomerGroupSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload;
      state.loading = false;
    },
    updateCustomerGroupFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateCustomerGroupDefault: (state) => {
      state.loading = false;
      state.success = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const {
  updateCustomerGroupRequest,
  updateCustomerGroupSuccess,
  updateCustomerGroupFailure,
  updateCustomerGroupDefault,
} = updateCustomerGroupSlice.actions;

export default updateCustomerGroupSlice.reducer;
