import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const deleteSubaccountSlice = createSlice({
  name: 'subAccount/deleteSubaccount',
  initialState,
  reducers: {
    deleteSubaccountRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
      state.error = '';
    },
    deleteSubaccountSuccess: (state, { payload }) => {
      state.success = true;
      state.loading = false;
      state.message = payload;
    },
    deleteSubaccountFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteSubaccountDefault: (state) => {
      state.loading = false;
      state.success = false;
      state.message = '';
      state.error = '';
    },
  },
});

export const { deleteSubaccountRequest, deleteSubaccountSuccess, deleteSubaccountFailure, deleteSubaccountDefault } =
  deleteSubaccountSlice.actions;

export default deleteSubaccountSlice.reducer;
