import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: {},
};

export const createBillingSlice = createSlice({
  name: 'financial/createBilling',
  initialState,
  reducers: {
    createBillingRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    createBillingSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
    },
    createBillingFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createBillingDefault: (state) => {
      state.loading = initialState?.loading;
      state.success = initialState?.success;
      state.error = initialState?.error;
      state.message = initialState?.message;
    },
  },
});

export const { createBillingDefault, createBillingFailure, createBillingRequest, createBillingSuccess } =
  createBillingSlice.actions;

export default createBillingSlice.reducer;
