import { createSlice } from '@reduxjs/toolkit';

export const subAccountSwitchSlice = createSlice({
  name: 'auth/subAccountSwitch',
  initialState: {
    token: '',
    error: '',
    loading: false,
    success: false,
  },
  reducers: {
    switchSubAccountRequest: (state) => {
      state.token = '';
      state.loading = true;
      state.success = false;
      state.error = '';
    },
    switchSubAccountSuccess: (state, action) => {
      state.token = action.payload;
      state.loading = false;
      state.success = true;
    },
    switchSubAccountFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    switchSubAccountDefault: (state, action) => {
      state.token = '';
      state.loading = false;
      state.success = false;
      state.error = '';
    },
  },
});

export const { switchSubAccountRequest, switchSubAccountSuccess, switchSubAccountFailure, switchSubAccountDefault } =
  subAccountSwitchSlice.actions;

export default subAccountSwitchSlice.reducer;
