import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const refundRideSlice = createSlice({
  name: 'ride/refundRide',
  initialState,
  reducers: {
    refundRideSliceRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
    },
    refundRideSliceSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload.message;
      state.loading = false;
    },
    refundRideSliceFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    refundRideSliceDefault: (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const { refundRideSliceDefault, refundRideSliceFailure, refundRideSliceRequest, refundRideSliceSuccess } =
  refundRideSlice.actions;

export default refundRideSlice.reducer;
