import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  message: '',
  loading: false,
  error: '',
  success: false,
  createdAt: '',
};

export const createNoteSlice = createSlice({
  name: 'vehicle/createNote',
  initialState,
  reducers: {
    createNoteRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
      state.error = '';
      state.createdAt = '';
    },
    createNoteSuccess: (state, { payload }) => {
      state.success = true;
      state.loading = false;
      state.message = 'Create note is successfully!';
      state.createdAt = payload?.createdAt;
    },
    createNoteFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createNoteDefault: (state) => {
      state.success = false;
      state.loading = false;
      state.error = '';
      state.message = '';
      state.createdAt = '';
    },
  },
});

export const { createNoteRequest, createNoteSuccess, createNoteFailure, createNoteDefault } = createNoteSlice.actions;

export default createNoteSlice.reducer;
