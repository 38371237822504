import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: '',
};

export const updateZoneSlice = createSlice({
  name: 'zones/updateZone',
  initialState,
  reducers: {
    onUpdateZoneRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    onUpdateZoneSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
    },
    onUpdateZoneFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    onUpdateZoneDefault: (state) => {
      state.loading = false;
      state.error = '';
      state.success = false;
      state.message = '';
    },
  },
});

export const { onUpdateZoneRequest, onUpdateZoneSuccess, onUpdateZoneFailure, onUpdateZoneDefault } =
  updateZoneSlice.actions;

export default updateZoneSlice.reducer;
