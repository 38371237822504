import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  history: {},
  loading: false,
  error: '',
};

export const historyVehicleSlice = createSlice({
  name: 'vehicle/historyVehicle',
  initialState,
  reducers: {
    getHistoryVehicleRequest: (state) => {
      state.loading = true;
      state.history = {};
      state.error = '';
    },
    getHistoryVehicleSuccess: (state, action) => {
      state.history = action.payload;
      state.loading = false;
    },
    getHistoryVehicleFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getHistoryVehicleDefault: (state) => {
      state.loading = false;
      state.history = {};
      state.error = '';
    },
  },
});

export const {
  getHistoryVehicleSuccess,
  getHistoryVehicleRequest,
  getHistoryVehicleFailure,
  getHistoryVehicleDefault,
} = historyVehicleSlice.actions;

export default historyVehicleSlice.reducer;
