import axios from 'axios';
import { REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE } from 'constants/api.constant';
import { RES_SUCCESS } from 'src/constants/api.constant';
import appConfig from 'src/configs/app.config';

const unauthorizedCode = [401];
let authToken = localStorage.getItem('authToken') ? JSON.parse(localStorage.getItem('authToken')) : null;

const BaseService = axios.create({
  timeout: 120000,
  baseURL: appConfig.apiPrefix,
  headers: {
    Accept: 'application/json',
  },
});

BaseService.interceptors.request.use(
  (config) => {
    authToken = localStorage.getItem('authToken') ? JSON.parse(localStorage.getItem('authToken')) : null;
    if (authToken) {
      config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${authToken?.accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { response } = error;
    const originalRequest = error.config;
    if (error.response?.data?.message === 'Token Has Expired' && !originalRequest._retry) {
      originalRequest._retry = true;
      const resp = await BaseService.post('/auth/refresh', {
        refreshToken: authToken?.refreshToken,
      });

      if (resp.data.status === RES_SUCCESS) {
        const data = resp.data.data;
        localStorage.setItem(
          'authToken',
          JSON.stringify({ accessToken: data.accessToken, refreshToken: data.refreshToken, signedIn: true })
        );

        return BaseService(originalRequest);
      }
    }

    if (response && unauthorizedCode.includes(response.status)) {
      localStorage.setItem('authToken', JSON.stringify({ accessToken: '', refreshToken: '', signedIn: false }));
    }

    return Promise.reject(error);
  }
);

export default BaseService;
