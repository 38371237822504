import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: {},
  billing: {},
};

export const getBillingSlice = createSlice({
  name: 'financial/getBilling',
  initialState,
  reducers: {
    getBillingRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    getBillingSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      state.billing = action.payload;
    },
    getBillingFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getBillingDefault: (state) => {
      state.loading = initialState.loading;
      state.success = initialState.success;
      state.error = initialState.error;
      state.message = initialState.message;
    },
  },
});

export const { getBillingDefault, getBillingFailure, getBillingRequest, getBillingSuccess } = getBillingSlice.actions;

export default getBillingSlice.reducer;
