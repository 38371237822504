import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
};

export const updateBundleSlice = createSlice({
  name: 'bundle/updateBundle',
  initialState,
  reducers: {
    updateBundleRequest: (state) => {
      state.loading = true;
      state.successUpdate = false;
      state.errorUpdate = '';
    },
    updateBundleSuccess: (state) => {
      state.successUpdate = true;
      state.loading = false;
    },
    updateBundleFailure: (state, action) => {
      state.loading = false;
      state.errorUpdate = action.payload;
    },
    updateBundleDefault: (state, action) => {
      state.loading = false;
      state.successUpdate = false;
      state.errorUpdate = '';
    },
  },
});

export const { updateBundleRequest, updateBundleSuccess, updateBundleFailure, updateBundleDefault } =
  updateBundleSlice.actions;

export default updateBundleSlice.reducer;
