import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  value: {},
};

export const createReservationDistanceSlice = createSlice({
  name: 'systemPreference/createReservationDistance',
  initialState,
  reducers: {
    createReservationDistanceRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.value = '';
    },
    createReservationDistanceSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.value = action.payload;
    },
    createReservationDistanceFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createReservationDistanceDefault: (state) => {
      state.loading = initialState?.loading;
      state.success = initialState?.success;
      state.error = initialState?.error;
      state.value = initialState?.value;
    },
  },
});

export const {
  createReservationDistanceRequest,
  createReservationDistanceSuccess,
  createReservationDistanceFailure,
  createReservationDistanceDefault,
} = createReservationDistanceSlice.actions;

export default createReservationDistanceSlice.reducer;
