import { createSlice } from '@reduxjs/toolkit';

export const listEmployeeActivitySlice = createSlice({
  name: 'auth/listEmployeeActivity',
  initialState: {
    employee: [],
    loading: false,
    pagination: {},
  },
  reducers: {
    getAllEmployeeRequest: (state) => {
      state.loading = true;
    },
    getAllEmployeeSuccess: (state, action) => {
      state.employee = action.payload?.responses;
      state.pagination = action.payload?.pagination;
      state.loading = false;
    },
    getAllEmployeeFailure: (state, action) => {
      state.loading = false;
      // state.employee = action.payload;
    },
  },
});

export const { getAllEmployeeFailure, getAllEmployeeRequest, getAllEmployeeSuccess } =
  listEmployeeActivitySlice.actions;

export default listEmployeeActivitySlice.reducer;
