import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  analytic: [],
  loading: false,
  error: '',
};

export const getListAnalyticSlice = createSlice({
  name: 'analytic/getList',
  initialState,
  reducers: {
    getAllAnalyticRequest: (state) => {
      state.loading = true;
    },
    getAllAnalyticSuccess: (state, action) => {
      state.analytic = action.payload;
      state.loading = false;
    },
    getAllAnalyticFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getAllAnalyticFailure, getAllAnalyticRequest, getAllAnalyticSuccess } = getListAnalyticSlice.actions;

export default getListAnalyticSlice.reducer;
