import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: {},
};

export const sendStatusSlice = createSlice({
  name: 'Send Status',
  initialState,
  reducers: {
    sendStatusRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    sendStatusSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
    },
    sendStatusFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    sendStatusDefault: (state) => {
      state.loading = initialState?.loading;
      state.success = initialState?.success;
      state.error = initialState?.error;
      state.message = initialState?.message;
    },
  },
});

export const { sendStatusRequest, sendStatusSuccess, sendStatusFailure, sendStatusDefault } = sendStatusSlice.actions;

export default sendStatusSlice.reducer;
