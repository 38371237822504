import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: true,
  error: '',
  detail: {},
};

export const rideVerificationDetailSlice = createSlice({
  name: 'rideVerification/rideVerificationDetail',
  initialState,
  reducers: {
    rideVerificationDetailRequest: (state) => {
      state.loading = true;
      state.detail = {};
      state.error = '';
    },
    rideVerificationDetailSuccess: (state, { payload }) => {
      state.success = true;
      state.detail = payload;
      state.loading = false;
    },
    rideVerificationDetailFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    rideVerificationDetailDefault: (state) => {
      state.success = false;
      state.loading = true;
      state.error = '';
    },
  },
});

export const {
  rideVerificationDetailRequest,
  rideVerificationDetailSuccess,
  rideVerificationDetailFailure,
  rideVerificationDetailDefault,
} = rideVerificationDetailSlice.actions;

export default rideVerificationDetailSlice.reducer;
