import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const importPromoSlice = createSlice({
  name: 'marketing/importPromo',
  initialState,
  reducers: {
    importPromoRequest: (state) => {
      state.loading = true;
    },
    importPromoSuccess: (state, action) => {
      state.success = true;
      state.message = action.payload;
      state.loading = false;
    },
    importPromoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetImportPromo: (state, action) => {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
      state.message = initialState.message;
    },
  },
});

export const { importPromoRequest, importPromoFailure, importPromoSuccess, resetImportPromo } =
  importPromoSlice.actions;

export default importPromoSlice.reducer;
