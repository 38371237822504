import ApiService from './ApiService';

export async function apiCreateZone(data) {
  return ApiService.fetchData({
    url: '/zones/',
    method: 'post',
    data,
  });
}

export async function apiGetAllZone(params) {
  return ApiService.fetchData({
    url: '/zones/',
    method: 'get',
    params,
  });
}

export async function apiGetAllPolygon() {
  return ApiService.fetchData({
    url: '/zones/area/',
    method: 'get',
  });
}

export async function apiCreatePolygon(data) {
  return ApiService.fetchData({
    url: `/zones/area/`,
    method: 'post',
    data,
  });
}

export async function apiGetZoneById({ id }) {
  return ApiService.fetchData({
    url: `/zones/${id}`,
    method: 'get',
  });
}

export async function apiUpdateZoneById({ id, data }) {
  return ApiService.fetchData({
    url: `/zones/${id}`,
    method: 'put',
    data,
  });
}
export async function apiDeleteZoneById({ id }) {
  return ApiService.fetchData({
    url: `/zones/${id}`,
    method: 'delete',
  });
}
export async function apiDeletePolygonById({ id }) {
  return ApiService.fetchData({
    url: `/zones/area/${id}`,
    method: 'delete',
  });
}
export async function apiUpdatePolygonById({ id, data }) {
  return ApiService.fetchData({
    url: `/zones/area/${id}`,
    method: 'put',
    data,
  });
}

export async function apiUpdateDescZone(data) {
  return ApiService.fetchData({
    url: `/zones/setting`,
    method: 'post',
    data,
  });
}
