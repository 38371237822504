import { combineReducers } from '@reduxjs/toolkit';
import listBundle from './listBundleSlice';
import getBundleDetailById from './getBundleDetailByIdSlice';
import createBundle from './createBundleSlice';
import updateBundle from './updateBundleSlice';
import deleteBundleById from './deleteBundleByIdSlice';
import listBundleHistory from './listBundleHistorySlice';
import deleteBundleHistoryById from './deleteBundleHistoryByIdSlice';
import optionsBundle from './getOptionsBundleSlice';

const reducer = combineReducers({
  listBundle,
  getBundleDetailById,
  createBundle,
  updateBundle,
  deleteBundleById,
  listBundleHistory,
  deleteBundleHistoryById,
  optionsBundle,
});

export default reducer;
