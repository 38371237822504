export const NAME_FILTER_CUSTOMER = {
  wallet: 'LAST_IOT_CUSTOMER',
  totalRide: 'TOTAL_RIDE_CUSTOMER',
  lastRide: 'LAST_RIDE_CUSTOMER',
  feedback: 'FEEDBACK_CUSTOMER',
};

export const STATUS_CUSTOMER = [
  { label: 'Debitors', value: 'WITH_DEBT' },
  { label: 'Blocked', value: 'BLOCKED' },
  { label: 'Saved card: yes', value: 'WITH_SAVED_CARD' },
  { label: 'Saved card: no', value: 'WITHOUT_SAVED_CARD' },
  { label: 'Deletion requested', value: 'DELETION_REQUESTED' },
  { label: 'Verified profiles', value: 'VERIFIED_PROFILES' },
  { label: 'Unverified profiles', value: 'UNVERIFIED_PROFILES' },
  { label: 'Subscribed for emails: yes', value: 'SUBSCRIBED_FOR_EMAILS' },
  { label: 'Subscribed for emails: no', value: 'UNSUBSCRIBED_FOR_EMAILS' },
];
