import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
};

export const uploadDocumentVehicleSlice = createSlice({
  name: 'vehicle/uploadDocumentVehicle',
  initialState,
  reducers: {
    uploadDocumentVehicleRequest: (state) => {
      state.loading = true;
      state.success = false;
    },
    uploadDocumentVehicleSuccess: (state, { payload }) => {
      state.success = true;
      state.loading = false;
    },
    uploadDocumentVehicleFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    uploadDocumentVehicleDefault: (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = '';
    },
  },
});

export const {
  uploadDocumentVehicleRequest,
  uploadDocumentVehicleSuccess,
  uploadDocumentVehicleFailure,
  uploadDocumentVehicleDefault,
} = uploadDocumentVehicleSlice.actions;

export default uploadDocumentVehicleSlice.reducer;
