import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: true,
  error: '',
  rideVerification: [],
  pagination: {},
  pendingKpi: 0,
  rejectedKpi: 0,
};

export const allRideVerificationSlice = createSlice({
  name: 'rideVerification/allRideVerification',
  initialState,
  reducers: {
    getAllRideVerificationRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.rideVerification = [];
      state.error = '';
      state.pagination = {};
    },
    getAllRideVerificationSuccess: (state, { payload }) => {
      state.success = true;
      state.rideVerification = payload?.responses;
      state.pagination = payload?.pagination;
      state.pendingKpi = payload?.pendingKpi;
      state.rejectedKpi = payload?.rejectedKpi;
      state.loading = false;
    },
    getAllRideVerificationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getAllRideVerificationRequest,
  getAllRideVerificationSuccess,
  getAllRideVerificationFailure,
  getAllRideVerificationDefault,
} = allRideVerificationSlice.actions;

export default allRideVerificationSlice.reducer;
