import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  appLocalizations: [],
  pagination: {},
  loading: false,
  error: '',
};

export const listAppLocalizationSlice = createSlice({
  name: 'appLocalization/listAppLocalization',
  initialState,
  reducers: {
    getListAppLocalizationRequest: (state) => {
      state.loading = true;
    },
    getListAppLocalizationSuccess: (state, action) => {
      state.appLocalizations = action.payload?.responses;
      state.pagination = action.payload?.pagination;
      state.loading = false;
    },
    getListAppLocalizationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getListAppLocalizationSuccess, getListAppLocalizationRequest, getListAppLocalizationFailure } =
  listAppLocalizationSlice.actions;

export default listAppLocalizationSlice.reducer;
