export const PERIODS = [
  { label: 'Auto update', value: 'AUTO_LAST_10_MIN' },
  { label: 'Last 10 min', value: 'LAST_10_MIN' },
  { label: 'Last 30 min', value: 'LAST_30_MIN' },
  { label: 'Last 1 hour', value: 'LAST_1_HOUR' },
  { label: 'Last 2 hours', value: 'LAST_2_HOURS' },
  { label: 'Last 6 hours', value: 'LAST_6_HOURS' },
  { label: 'Last 12 hours', value: 'LAST_12_HOURS' },
  { label: 'Last day', value: 'LAST_1_DAY' },
];

export const STATUS = [
  { label: 'Available', value: 'AVAILABLE' },
  { label: 'In Use', value: 'IN_USE' },
  { label: 'Low Charge', value: 'LOW_CHARGE' },
  { label: 'Charging', value: 'CHARGING' },
  { label: 'Unresponsive', value: 'UNRESPONSIVE' },
  { label: 'Needs Rebalancing', value: 'NEED_REBALANCING' },
  { label: 'Needs Investigation', value: 'NEED_INVESTIGATION' },
  { label: 'Transportation', value: 'TRANSPORTATION' },
  { label: 'Storage', value: 'STORAGE' },
  { label: 'Not ready', value: 'NOT_READY' },
];

export const STATUS_FOR_ONE = [
  { label: 'Available', value: 'AVAILABLE' },
  { label: 'Discharged', value: 'DISCHARGED' },
  { label: 'Charging', value: 'CHARGING' },
  { label: 'Needs investigation', value: 'NEED_INVESTIGATION' },
  { label: 'Maintenance', value: 'MAINTENANCE' },
  { label: 'Transportation', value: 'TRANSPORTATION' },
  { label: 'Storage', value: 'STORAGE' },
  { label: 'Stolen', value: 'STOLEN' },
  { label: 'Not ready', value: 'NOT_READY' },
];

export const NAME_FILTER = {
  lastIot: 'LAST_IOT',
  lastRide: 'LAST_RIDE',
  totalRide: 'TOTAL_RIDE',
  vehicleBattery: 'VEHICLE_BATTERY',
  iotBattery: 'IOT_BATTERY',
};

export const COMMAND = {
  UNLOCK: {
    LABEL: 'UNLOCK',
    CODE: '{"data": "mcan_cmd:0"}',
  },
  LOCK: {
    LABEL: 'LOCK',
    CODE: '{"data": "mcan_cmd:1"}',
  },
  OPEN_SEAT: {
    LABEL: 'OPEN_SEAT',
    CODE: '{"data": "mcan_cmd:4"}',
  },
  OPEN_TOP_CASE: {
    LABEL: 'OPEN_TOP_CASE',
    CODE: '{"data": "mcan_cmd:2"}',
  },
  CLOSE_TOP_CASE: {
    LABEL: 'CLOSE_TOP_CASE',
    CODE: '{"data": "mcan_cmd:3"}',
  },
};
