import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: true,
  error: '',
  users: [],
  pagination: {},
};

export const allUserSlice = createSlice({
  name: 'user/allUser',
  initialState,
  reducers: {
    getAllUsersRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
    },
    getAllUsersSuccess: (state, { payload }) => {
      state.success = true;
      state.users = payload?.responses;
      state.pagination = payload?.pagination;
      state.loading = false;
    },
    getAllUsersFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getAllUsersRequest, getAllUsersSuccess, getAllUsersFailure, getAllUsersDefault } = allUserSlice.actions;

export default allUserSlice.reducer;
