import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: {},
  data: [],
};

export const getMessageSlice = createSlice({
  name: 'settings/system-reference',
  initialState,
  reducers: {
    getMessageRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    getMessageSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      state.data = action.payload;
    },
    getMessageFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getMessageDefault: (state) => {
      state.loading = initialState.loading;
      state.success = initialState.success;
      state.error = initialState.error;
      state.message = initialState.message;
    },
  },
});

export const { getMessageRequest, getMessageSuccess, getMessageFailure, getMessageDefault } = getMessageSlice.actions;

export default getMessageSlice.reducer;
