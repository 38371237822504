import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  customerGroups: [],
  pagination: {},
  loading: false,
  error: '',
};

export const listCustomerGroupSlice = createSlice({
  name: 'customerGroup/listCustomerGroup',
  initialState,
  reducers: {
    getListCustomerGroupRequest: (state) => {
      state.loading = true;
    },
    getListCustomerGroupSuccess: (state, action) => {
      state.customerGroups = action.payload?.responses;
      state.pagination = action.payload?.pagination;
      state.loading = false;
    },
    getListCustomerGroupFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getListCustomerGroupSuccess, getListCustomerGroupRequest, getListCustomerGroupFailure } =
  listCustomerGroupSlice.actions;

export default listCustomerGroupSlice.reducer;
