import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Table = React.forwardRef((props, ref) => {
	const {
    borderlessRow,
    children,
    className,
    hoverable,
    compact,
    oveerflow = true,
    asElement: Component,
    data,
    ...rest
  } = props;

	const tableClass = classNames(
		Component === 'table' ? 'table-default' : 'table-flex',
		hoverable && 'table-hover',
		compact && 'table-compact',
		borderlessRow && 'borderless-row',
		className
	)

	return (
		<div
      	className={classNames(
        oveerflow && "overflow-x-auto",
        data.length === 0 && "overflow-y-hidden"
      )}
    >
      <Component className={tableClass} {...rest} ref={ref}>
        {children}
      </Component>
    </div>
  );
	})

Table.propTypes = {
	hoverable: PropTypes.bool,
	compact: PropTypes.bool,
	asElement: PropTypes.string,
	borderlessRow: PropTypes.bool,
	data: PropTypes.array,
}

Table.defaultProps = {
	hoverable: true,
	compact: false,
	asElement: 'table',
	borderlessRow: false,
	data:[]
}

export default Table
