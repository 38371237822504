import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const deleteCustomerGroupSlice = createSlice({
  name: 'customerGroup/deleteCustomerGroupMarketing',
  initialState,
  reducers: {
    deleteCustomerGroupRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    deleteCustomerGroupSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload;
      state.loading = false;
    },
    deleteCustomerGroupFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteCustomerGroupDefault: (state) => {
      state.loading = false;
      state.success = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const {
  deleteCustomerGroupRequest,
  deleteCustomerGroupSuccess,
  deleteCustomerGroupFailure,
  deleteCustomerGroupDefault,
} = deleteCustomerGroupSlice.actions;

export default deleteCustomerGroupSlice.reducer;
