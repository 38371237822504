import { combineReducers } from '@reduxjs/toolkit';
import getAllTypeSetting from './getAllTypeSettingSlice';
import getTermAndCondition from './getTermAndConditionSlice';
import getPrivacyPolicy from './getPrivacyPolicySlice';
import createTermAndCondition from './createTermAndConditionSlice';
import createPrivacyPolicy from './createPrivacyPolicySlice';

const reducer = combineReducers({
  getAllTypeSetting,
  getTermAndCondition,
  getPrivacyPolicy,
  createTermAndCondition,
  createPrivacyPolicy,
});

export default reducer;
