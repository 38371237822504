import { combineReducers } from '@reduxjs/toolkit';
import listSubConfig from './getListSubConfig';
import updateSubConfig from './updateSubConfig';
import listTransaction from './getListTransaction';
import paymentTransaction from './paymentTransactionSlice';

const reducer = combineReducers({
  listSubConfig,
  updateSubConfig,
  listTransaction,
  paymentTransaction,
});

export default reducer;
