import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: {},
};

export const createPrivacyPolicySlice = createSlice({
  name: 'Create Privacy Policy',
  initialState,
  reducers: {
    createPrivacyPolicyRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    createPrivacyPolicySuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
    },
    createPrivacyPolicyFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createPrivacyPolicyDefault: (state) => {
      state.loading = initialState?.loading;
      state.success = initialState?.success;
      state.error = initialState?.error;
      state.message = initialState?.message;
    },
  },
});

export const {
  createPrivacyPolicyRequest,
  createPrivacyPolicySuccess,
  createPrivacyPolicyFailure,
  createPrivacyPolicyDefault,
} = createPrivacyPolicySlice.actions;

export default createPrivacyPolicySlice.reducer;
