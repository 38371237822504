import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  list: [],
  loading: false,
  error: '',
  pagination: {},
  descSetting: {},
};

export const listZoneSlice = createSlice({
  name: 'zones/listZone',
  initialState,
  reducers: {
    onGetListZoneRequest: (state) => {
      state.loading = true;
      state.list = [];
      state.pagination = {};
      state.descSetting = {};
      state.error = '';
    },
    onGetListZoneSuccess: (state, action) => {
      state.list = action.payload.responses;
      state.pagination = action.payload.pagination;
      state.descSetting = action.payload?.zone;
      state.loading = false;
    },
    onGetListZoneFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    onGetListZoneDefault: (state) => {
      state.loading = true;
      state.list = [];
      state.pagination = {};
      state.descSetting = {};
      state.error = '';
    },
  },
});

export const { onGetListZoneRequest, onGetListZoneSuccess, onGetListZoneFailure, onGetListZoneDefault } =
  listZoneSlice.actions;

export default listZoneSlice.reducer;
