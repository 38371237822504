import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const bulkCustomerSlice = createSlice({
  name: 'customer/bulkCustomer',
  initialState,
  reducers: {
    bulkCustomerRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
      state.error = '';
    },
    bulkCustomerSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload;
      state.loading = false;
    },
    bulkCustomerFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    bulkCustomerDefault: (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const { bulkCustomerRequest, bulkCustomerSuccess, bulkCustomerFailure, bulkCustomerDefault } =
  bulkCustomerSlice.actions;

export default bulkCustomerSlice.reducer;
