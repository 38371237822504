import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  zone: {},
  loading: false,
  error: '',
};

export const zoneByIdSlice = createSlice({
  name: 'zones/zoneById',
  initialState,
  reducers: {
    onGetZoneByIdRequest: (state) => {
      state.loading = true;
      state.zone = {};
    },
    onGetZoneByIdSuccess: (state, action) => {
      state.zone = action.payload;
      state.loading = false;
    },
    onGetZoneByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { onGetZoneByIdRequest, onGetZoneByIdSuccess, onGetZoneByIdFailure } = zoneByIdSlice.actions;

export default zoneByIdSlice.reducer;
