import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
  data: {},
};

export const commentRideVerificationSlice = createSlice({
  name: 'rideVerification/commentRideVerification',
  initialState,
  reducers: {
    commentRideVerificationRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
      state.error = '';
      state.data = {};
    },
    commentRideVerificationSuccess: (state, { payload }) => {
      state.success = true;
      state.data = payload.data;
      state.loading = false;
      state.createdAt = payload.createdAt;
    },
    commentRideVerificationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    commentRideVerificationDefault: (state) => {
      state.loading = false;
      state.success = false;
      state.message = '';
      state.error = '';
      state.data = {};
    },
  },
});

export const {
  commentRideVerificationRequest,
  commentRideVerificationSuccess,
  commentRideVerificationFailure,
  commentRideVerificationDefault,
} = commentRideVerificationSlice.actions;

export default commentRideVerificationSlice.reducer;
