import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const deleteBundleByIdSlice = createSlice({
  name: 'bundle/deleteBundleById',
  initialState,
  reducers: {
    deleteBundleByIdRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
      state.error = '';
    },
    deleteBundleByIdSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload;
      state.loading = false;
    },
    deleteBundleByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteBundleByIdDefault: (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const { deleteBundleByIdRequest, deleteBundleByIdSuccess, deleteBundleByIdFailure, deleteBundleByIdDefault } =
  deleteBundleByIdSlice.actions;

export default deleteBundleByIdSlice.reducer;
