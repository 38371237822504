import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  notes: [],
  loading: false,
  error: '',
  success: false,
  pagination: {},
};

export const getNotesForVehicleDetailSlice = createSlice({
  name: 'vehicle/getNotesForVehicleDetail',
  initialState,
  reducers: {
    getNotesForVehicleDetailRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.notes = [];
      state.pagination = {};
      state.error = '';
    },
    getNotesForVehicleDetailSuccess: (state, { payload }) => {
      state.success = true;
      state.loading = false;
      state.notes = payload?.responses;
      state.pagination = payload?.pagination;
    },
    getNotesForVehicleDetailFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getNotesForVehicleDetailDefault: (state) => {
      state.success = false;
      state.loading = false;
      state.error = '';
      state.notes = [];
      state.pagination = {};
    },
  },
});

export const {
  getNotesForVehicleDetailRequest,
  getNotesForVehicleDetailSuccess,
  getNotesForVehicleDetailFailure,
  getNotesForVehicleDetailDefault,
} = getNotesForVehicleDetailSlice.actions;

export default getNotesForVehicleDetailSlice.reducer;
