import { combineReducers } from '@reduxjs/toolkit';
import createReservationTime from './createReservationTimeSlice';
import createReservationDistance from './createReservationDistanceSlice';
import getReservationTime from './getReservationTimeSlice';
import createEndTheRide from './createEndTheRideSlice';
import createRebalancing from './createRebalancingSlice';
import createRideDistance from './createRideDistanceSlice';
import createRatioPayout from './createRatioPayoutSlice';

const reducer = combineReducers({
  createReservationTime,
  createReservationDistance,
  getReservationTime,
  createEndTheRide,
  createRebalancing,
  createRideDistance,
  createRatioPayout,
});

export default reducer;
