import { combineReducers } from '@reduxjs/toolkit';
import newPromo from './newPromoSlice';
import listPromo from './listPromoSlice';
import deletePromoMarketing from './deletePromoSlice';
import updatePromo from './updatePromoSlice';
import importPromo from './importPromoSlice';
import exportPromo from './exportPromoSlice';
const reducer = combineReducers({
  newPromo,
  listPromo,
  deletePromoMarketing,
  updatePromo,
  importPromo,
  exportPromo,
});

export default reducer;
