import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  dataPromos: [],
  pagination: {},
  loading: false,
  error: '',
};

export const listExportPromoSlice = createSlice({
  name: 'marketing/exportPromo',
  initialState,
  reducers: {
    getListExportPromoRequest: (state) => {
      state.loading = true;
    },
    getListExportPromoSuccess: (state, action) => {
      state.dataPromos = action.payload?.responses;
      state.pagination = action.payload?.pagination;
      state.loading = false;
    },
    getListExportPromoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getListExportPromoFailure, getListExportPromoRequest, getListExportPromoSuccess } =
  listExportPromoSlice.actions;

export default listExportPromoSlice.reducer;
