import { combineReducers } from '@reduxjs/toolkit';
import allSubaccount from './allSubaccountSlice';
import createSubaccount from './createSubaccountSlice';
import detailSubaccount from './detailSubaccountSlice';
import updateSubaccount from './updateSubaccountSlice';
import deleteSubaccount from './deleteSubaccountSlice';
const reducer = combineReducers({
  allSubaccount,
  createSubaccount,
  detailSubaccount,
  updateSubaccount,
  deleteSubaccount,
});

export default reducer;
