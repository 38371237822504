import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const createSubaccountSlice = createSlice({
  name: 'subAccount/createSubaccount',
  initialState,
  reducers: {
    createSubaccountRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
      state.error = '';
    },
    createSubaccountSuccess: (state, { payload }) => {
      state.success = true;
      state.loading = false;
      state.message = payload;
    },
    createSubaccountFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createSubaccountDefault: (state) => {
      state.loading = false;
      state.success = false;
      state.message = '';
      state.error = '';
    },
  },
});

export const { createSubaccountRequest, createSubaccountSuccess, createSubaccountFailure, createSubaccountDefault } =
  createSubaccountSlice.actions;

export default createSubaccountSlice.reducer;
