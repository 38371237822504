import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  bundle: {},
  loading: false,
  error: '',
  location: [],
  success: true,
};

export const getBundleDetailByIdSlice = createSlice({
  name: 'bundle/getBundleDetail',
  initialState,
  reducers: {
    getBundleDetailByIdRequest: (state) => {
      state.loading = true;
      state.bundle = {};
      state.error = '';
      state.location = [];
      state.success = false;
    },
    getBundleDetailByIdSuccess: (state, action) => {
      state.bundle = { ...action.payload?.result, subAccount: [...action.payload?.subAccount] };
      state.location = action.payload?.location;
      state.loading = false;
      state.success = true;
    },
    getBundleDetailByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getBundleDetailByIdDefault: (state, action) => {
      state.loading = false;
      state.bundle = {};
      state.error = '';
      state.success = false;
    },
  },
});

export const {
  getBundleDetailByIdRequest,
  getBundleDetailByIdSuccess,
  getBundleDetailByIdFailure,
  getBundleDetailByIdDefault,
} = getBundleDetailByIdSlice.actions;

export default getBundleDetailByIdSlice.reducer;
