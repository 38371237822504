import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
  data: {},
};

export const changeStatusSlice = createSlice({
  name: 'rideVerification/changeStatus',
  initialState,
  reducers: {
    changeStatusRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
      state.error = '';
      state.data = {};
    },
    changeStatusSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload?.message;
      state.data = payload?.data;
      state.loading = false;
    },
    changeStatusFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeStatusDefault: (state) => {
      state.loading = false;
      state.success = false;
      state.message = '';
      state.error = '';
      state.data = {};
    },
  },
});

export const { changeStatusRequest, changeStatusSuccess, changeStatusFailure, changeStatusDefault } =
  changeStatusSlice.actions;

export default changeStatusSlice.reducer;
