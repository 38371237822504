import { combineReducers } from '@reduxjs/toolkit';
import listCustomer from './listCustomerSlice';
import listCustomerSearch from './listCustomerSearchSlice';
import createCustomer from './createCustomerSlice';
import listCustomerSelected from './listCustomerSelectedSlice';
import listCustomerType from './listCustomerTypeSlice';
import getCustomerById from './getCustomerByIdSlice';
import deleteCustomerById from './deleteCustomerByIdSlice';
import bulkCustomer from './bulkCustomerSlice';
import getActivityCustomer from './getActivityCustomerSlice';

const reducer = combineReducers({
  listCustomer,
  listCustomerSearch,
  createCustomer,
  listCustomerSelected,
  listCustomerType,
  getCustomerById,
  deleteCustomerById,
  bulkCustomer,
  getActivityCustomer,
});

export default reducer;
