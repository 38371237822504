import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  activities: [],
  pagination: {},
};

export const getActivityCustomerSlice = createSlice({
  name: 'activity customer',
  initialState,
  reducers: {
    getActivityCustomerRequest: (state) => {
      state.loading = true;
    },
    getActivityCustomerSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.activities = action.payload?.responses;
      state.pagination = action.payload?.pagination;
    },
    getActivityCustomerFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getActivityCustomerDefault: (state) => {
      state.loading = initialState.loading;
      state.success = initialState.success;
      state.error = initialState.error;
      state.activities = initialState.activity;
      state.pagination = initialState.pagination;
    },
  },
});

export const {
  getActivityCustomerRequest,
  getActivityCustomerSuccess,
  getActivityCustomerFailure,
  getActivityCustomerDefault,
} = getActivityCustomerSlice.actions;

export default getActivityCustomerSlice.reducer;
