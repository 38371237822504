import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: true,
  error: '',
  subaccounts: [],
  pagination: {},
};

export const allSubaccountSlice = createSlice({
  name: 'subAccount/allSubaccount',
  initialState,
  reducers: {
    getAllSubaccountRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
    },
    getAllSubaccountSuccess: (state, { payload }) => {
      state.success = true;
      state.subaccounts = payload?.responses;
      state.pagination = payload?.pagination;
      state.loading = false;
    },
    getAllSubaccountFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getAllSubaccountRequest, getAllSubaccountSuccess, getAllSubaccountFailure, getAllSubaccountDefault } =
  allSubaccountSlice.actions;

export default allSubaccountSlice.reducer;
