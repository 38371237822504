import { combineReducers } from '@reduxjs/toolkit';
import getAllTypeSetting from './getAllTypeSettingSlice';
import createAdvert from './createAdvertSlice';
import getAdvert from './getAdvertSlice';

const reducer = combineReducers({
  getAllTypeSetting,
  createAdvert,
  getAdvert,
});

export default reducer;
