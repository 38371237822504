import { createSlice, current } from '@reduxjs/toolkit';

export const initialState = {
  selectedRows: [],
};

export const listRideSlice = createSlice({
  name: 'ride/listRideSelected',
  initialState,
  reducers: {
    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
    },
    addRowItem: (state, { payload }) => {
      const currentState = current(state);
      if (!currentState.selectedRows.includes(payload)) {
        return {
          selectedRows: [...currentState.selectedRows, ...payload],
        };
      }
    },
    removeRowItem: (state, { payload }) => {
      const currentState = current(state);
      if (currentState.selectedRows.includes(payload)) {
        return {
          selectedRows: currentState.selectedRows.filter((id) => id !== payload),
        };
      }
    },
  },
});

export const { setSelectedRows, setSelectedRow, addRowItem, removeRowItem } = listRideSlice.actions;

export default listRideSlice.reducer;
