import { combineReducers } from '@reduxjs/toolkit';
import listIoT from './listIoTSlice';
import createIoT from './createIoTSlice';
import allIot from './allIoTSlice';
import updateIoT from './updateIoTSlice';
import deleteIoT from './deleteIoTByIdSlice';
import importIot from './importIotSlice';
import listIoTDataLog from './listIoTDataLogSlice';

const reducer = combineReducers({
  listIoT,
  createIoT,
  allIot,
  updateIoT,
  deleteIoT,
  importIot,
  listIoTDataLog,
});

export default reducer;
