import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const deleteBundleHistoryByIdSlice = createSlice({
  name: 'bundle/deleteBundleHistoryById',
  initialState,
  reducers: {
    deleteBundleHistoryByIdRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
      state.error = '';
    },
    deleteBundleHistoryByIdSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload;
      state.loading = false;
    },
    deleteBundleHistoryByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteBundleHistoryByIdDefault: (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const {
  deleteBundleHistoryByIdRequest,
  deleteBundleHistoryByIdSuccess,
  deleteBundleHistoryByIdFailure,
  deleteBundleHistoryByIdDefault,
} = deleteBundleHistoryByIdSlice.actions;

export default deleteBundleHistoryByIdSlice.reducer;
