import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  listType: [],
  error: '',
};

export const listVehicleTypeSlice = createSlice({
  name: 'vehicle/listVehicleType',
  initialState,
  reducers: {
    onGetListVehicleTypeRequest: (state) => {},
    onGetListVehicleTypeSuccess: (state, action) => {
      state.listType = action.payload;
      state.error = '';
    },
    onGetListVehicleTypeFailure: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { onGetListVehicleTypeRequest, onGetListVehicleTypeSuccess, onGetListVehicleTypeFailure } =
  listVehicleTypeSlice.actions;

export default listVehicleTypeSlice.reducer;
