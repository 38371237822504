import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  iots: [],
  pagination: {},
  loading: false,
  error: '',
};

export const listIoTSlice = createSlice({
  name: 'iot/listIoT',
  initialState,
  reducers: {
    getListIoTRequest: (state) => {
      state.loading = true;
    },
    getListIoTSuccess: (state, action) => {
      state.iots = action.payload?.responses;
      state.pagination = action.payload?.pagination;
      state.loading = false;
    },
    getListIoTFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getListIoTSuccess, getListIoTRequest, getListIoTFailure } = listIoTSlice.actions;

export default listIoTSlice.reducer;
