import { combineReducers } from '@reduxjs/toolkit';
import createMessage from './createMessageSlice';
import getMessage from './getMessageSlice';
import sendStatus from './sendStatusSlice';

const reducer = combineReducers({
  createMessage,
  getMessage,
  sendStatus,
});

export default reducer;
