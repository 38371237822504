import { combineReducers } from '@reduxjs/toolkit';
import createCustomerGroup from './createCustomerGroupSlice';
import listCustomerGroup from './listCustomerGroupSlice';
import deleteCustomerGroup from './deleteCustomerGroupByIdSlice';
import updateCustomerGroup from './updateCustomerGroupByIdSlice';
import getCustomerGroupById from './getCustomerGroupByIdSlice';

const reducer = combineReducers({
  createCustomerGroup,
  getCustomerGroupById,
  listCustomerGroup,
  deleteCustomerGroup,
  updateCustomerGroup,
});

export default reducer;
