import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: {},
};

export const updateSubConfigSlice = createSlice({
  name: 'financeManagement/updateSubConfig',
  initialState,
  reducers: {
    updateSubConfigRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    updateSubConfigSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
    },
    updateSubConfigFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateSubConfigDefault: (state) => {
      state.loading = initialState?.loading;
      state.success = initialState?.success;
      state.error = initialState?.error;
      state.message = initialState?.message;
    },
  },
});

export const { updateSubConfigFailure, updateSubConfigRequest, updateSubConfigSuccess, updateSubConfigDefault } =
  updateSubConfigSlice.actions;

export default updateSubConfigSlice.reducer;
