import { useDispatch } from 'react-redux';
import { apiGetAllVehicle } from 'services/VehicleService';
import {
  apiBulkMultipleVehicleType,
  apiCreateNoteForVehicleDetail,
  apiCreateVehicle,
  apiDeleteVehicleById,
  apiGetListVehicleType,
  apiGetVehicleActivity,
  apiGetNotesForVehicleDetail,
  apiGetVehicleById,
  apiUpdateVehicle,
  apiDownloadQRById,
  apiImportVehicle,
  apiGetHistoryVehicle,
  apiGetDocumentVehicle,
  apiUploadDocumentVehicle,
  apiCallCommandVehicle,
  apiDeleteDocument,
} from 'src/services/VehicleService';
import {
  onCreateVehicleFailure,
  onCreateVehicleRequest,
  onCreateVehicleSuccess,
} from 'src/store/vehicle/createVehicleSlice';
import {
  bulkDownloadVehicleFailure,
  bulkDownloadVehicleRequest,
  bulkDownloadVehicleSuccess,
  bulkVehicleFailure,
  bulkVehicleRequest,
  bulkVehicleSuccess,
} from 'src/store/vehicle/bulkVehicleSlice';
import {
  onGetListVehicleTypeFailure,
  onGetListVehicleTypeRequest,
  onGetListVehicleTypeSuccess,
} from 'src/store/vehicle/listVehicleTypeSlice';
import {
  onGetListVehicleFailure,
  onGetListVehicleRequest,
  onGetListVehicleSuccess,
} from 'store/vehicle/listVehicleSlice';
import {
  deleteVehicleByIdFailure,
  deleteVehicleByIdRequest,
  deleteVehicleByIdSuccess,
} from 'src/store/vehicle/deleteVehicleByIdSlice';
import {
  getVehicleByIdFailure,
  getVehicleByIdRequest,
  getVehicleByIdSuccess,
} from 'src/store/vehicle/getVehicleByIdSlice';
import {
  onUpdateVehicleFailure,
  onUpdateVehicleRequest,
  onUpdateVehicleSuccess,
} from 'src/store/vehicle/updateVehicleByIdSlice';
import {
  getListVehicleActivityFailure,
  getListVehicleActivityRequest,
  getListVehicleActivitySuccess,
} from 'src/store/vehicle/getVehicleActivitySlice';

import {
  getNotesForVehicleDetailFailure,
  getNotesForVehicleDetailRequest,
  getNotesForVehicleDetailSuccess,
} from 'src/store/vehicle/getNotesForVehicleDetailSlice';

import {
  createNoteForVehicleDetailFailure,
  createNoteForVehicleDetailRequest,
  createNoteForVehicleDetailSuccess,
} from 'src/store/vehicle/createNoteForVehicleDetailSlice';
import { downloadQrVehicleRequest, downloadQrVehicleSuccess } from 'src/store/vehicle/downloadQrVehicleByIdSlice';
import { RES_SUCCESS } from 'src/constants/api.constant';
import { importVehicleFailure, importVehicleRequest, importVehicleSuccess } from 'src/store/vehicle/importVehicleSlice';
import {
  getHistoryVehicleFailure,
  getHistoryVehicleRequest,
  getHistoryVehicleSuccess,
} from 'src/store/vehicle/historyVehicleSlice';
import {
  getDocumentVehicleFailure,
  getDocumentVehicleRequest,
  getDocumentVehicleSuccess,
} from 'src/store/vehicle/getDocumentVehicleSlice';
import {
  uploadDocumentVehicleFailure,
  uploadDocumentVehicleRequest,
  uploadDocumentVehicleSuccess,
} from 'src/store/vehicle/uploadDocumentVehicleSlice';
import {
  callCommandVehicleRequest,
  callCommandVehicleSuccess,
  callCommandVehicleFailure,
} from 'src/store/vehicle/callCommandVehicleSlice';
import {
  deleteDocumentFailure,
  deleteDocumentRequest,
  deleteDocumentSuccess,
} from 'src/store/vehicle/deleteDocumentSlice';

function useVehicle() {
  const dispatch = useDispatch();

  const getAllVehicle = async (values) => {
    try {
      dispatch(onGetListVehicleRequest());
      const resp = await apiGetAllVehicle(values);
      if (resp.data) {
        const data = resp?.data;
        dispatch(onGetListVehicleSuccess(data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(onGetListVehicleFailure(error));
    }
  };

  const postNewVehicle = async (values) => {
    try {
      dispatch(onCreateVehicleRequest());
      const resp = await apiCreateVehicle(values);
      if (resp) {
        dispatch(onCreateVehicleSuccess());
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(onCreateVehicleFailure(error));
    }
  };

  const putUpdateVehicle = async (values) => {
    try {
      dispatch(onUpdateVehicleRequest());
      const resp = await apiUpdateVehicle(values);
      if (resp) {
        dispatch(onUpdateVehicleSuccess());
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(onUpdateVehicleFailure(error));
    }
  };

  const getListVehicleType = async (values) => {
    try {
      dispatch(onGetListVehicleTypeRequest());
      const resp = await apiGetListVehicleType(values);
      if (resp.data) {
        const data = resp?.data;
        dispatch(onGetListVehicleTypeSuccess(data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(onGetListVehicleTypeFailure(error));
    }
  };

  const bulkMultipleVehicle = async (values) => {
    try {
      dispatch(bulkVehicleRequest());
      const resp = await apiBulkMultipleVehicleType(values);
      if (resp.status === RES_SUCCESS) {
        dispatch(bulkVehicleSuccess(resp));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(bulkVehicleFailure(error));
    }
  };

  const deleteVehicleById = async (values) => {
    try {
      dispatch(deleteVehicleByIdRequest());
      const resp = await apiDeleteVehicleById(values);
      if (resp.status === RES_SUCCESS) {
        dispatch(deleteVehicleByIdSuccess(resp.message));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(deleteVehicleByIdFailure(error));
    }
  };

  const getVehicleById = async (values) => {
    try {
      dispatch(getVehicleByIdRequest());
      const resp = await apiGetVehicleById(values);
      if (resp.status === RES_SUCCESS) {
        const data = resp?.data;
        dispatch(getVehicleByIdSuccess(data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(getVehicleByIdFailure(error));
    }
  };

  const getVehicleActivity = async (values) => {
    try {
      dispatch(getListVehicleActivityRequest());
      const resp = await apiGetVehicleActivity(values);
      if (resp.status === RES_SUCCESS) {
        const data = resp?.data;
        dispatch(getListVehicleActivitySuccess(data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(getListVehicleActivityFailure(error));
    }
  };

  const getAllNotesForVehicleDetail = async (values) => {
    try {
      dispatch(getNotesForVehicleDetailRequest());
      const resp = await apiGetNotesForVehicleDetail(values);
      if (resp.status === RES_SUCCESS) {
        const data = resp?.data;
        dispatch(getNotesForVehicleDetailSuccess(data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(getNotesForVehicleDetailFailure(error));
    }
  };

  const createNoteForVehicleDetail = async (values) => {
    try {
      dispatch(createNoteForVehicleDetailRequest());
      const resp = await apiCreateNoteForVehicleDetail(values);
      if (resp.status === RES_SUCCESS) {
        dispatch(createNoteForVehicleDetailSuccess(resp?.data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(createNoteForVehicleDetailFailure(error));
    }
  };

  // download qr code
  const downloadQrVehicleById = async (values) => {
    try {
      dispatch(downloadQrVehicleRequest());
      const resp = await apiDownloadQRById(values);
      if (resp.status === RES_SUCCESS) {
        dispatch(downloadQrVehicleSuccess(resp));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(getVehicleByIdFailure(error));
    }
  };
  const bulkDownloadMultipleVehicle = async (values) => {
    try {
      dispatch(bulkDownloadVehicleRequest());
      const resp = await apiBulkMultipleVehicleType(values);
      if (resp.status === RES_SUCCESS) {
        dispatch(bulkDownloadVehicleSuccess(resp));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(bulkDownloadVehicleFailure(error));
    }
  };

  const importFileCSVVehicle = async (values) => {
    try {
      dispatch(importVehicleRequest());
      const resp = await apiImportVehicle(values);
      if (resp.status === RES_SUCCESS) {
        const data = resp?.message;
        dispatch(importVehicleSuccess(data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(importVehicleFailure(error));
    }
  };

  const getHistoryVehicle = async (values) => {
    try {
      dispatch(getHistoryVehicleRequest());
      const resp = await apiGetHistoryVehicle(values);
      if (resp.status === RES_SUCCESS) {
        const data = resp?.data;
        dispatch(getHistoryVehicleSuccess(data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(getHistoryVehicleFailure(error));
    }
  };

  const getDocumentVehicle = async (values) => {
    try {
      dispatch(getDocumentVehicleRequest());
      const resp = await apiGetVehicleById(values);
      if (resp.status === RES_SUCCESS) {
        const data = resp?.data;
        dispatch(getDocumentVehicleSuccess(data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(getDocumentVehicleFailure(error));
    }
  };

  const uploadDocumentVehicle = async (values) => {
    try {
      dispatch(uploadDocumentVehicleRequest());
      const resp = await apiUploadDocumentVehicle(values);
      if (resp.status === RES_SUCCESS) {
        const data = resp?.data;
        dispatch(uploadDocumentVehicleSuccess(data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(uploadDocumentVehicleFailure(error));
    }
  };

  const callCommandVehicle = async (values) => {
    try {
      dispatch(callCommandVehicleRequest());
      const resp = await apiCallCommandVehicle(values);
      if (resp.status === RES_SUCCESS) {
        const data = resp?.data;
        dispatch(callCommandVehicleSuccess(data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(callCommandVehicleFailure(error));
    }
  };

  const deleteDocument = async (values) => {
    try {
      dispatch(deleteDocumentRequest());
      const resp = await apiDeleteDocument(values);
      if (resp.status === RES_SUCCESS) {
        const data = resp?.data;
        dispatch(deleteDocumentSuccess(data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(deleteDocumentFailure(error));
    }
  };

  return {
    getAllVehicle,
    postNewVehicle,
    getListVehicleType,
    bulkMultipleVehicle,
    deleteVehicleById,
    getVehicleById,
    putUpdateVehicle,
    getVehicleActivity,
    getAllNotesForVehicleDetail,
    createNoteForVehicleDetail,
    downloadQrVehicleById,
    bulkDownloadMultipleVehicle,
    importFileCSVVehicle,
    getHistoryVehicle,
    getDocumentVehicle,
    uploadDocumentVehicle,
    callCommandVehicle,
    deleteDocument,
  };
}

export default useVehicle;
