import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: '',
};

export const deleteZoneSlice = createSlice({
  name: 'zones/deleteZone',
  initialState,
  reducers: {
    deleteZoneRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    deleteZoneSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
    },
    deleteZoneFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteZoneDefault: (state) => {
      state.loading = false;
      state.error = '';
      state.success = false;
      state.message = '';
    },
  },
});

export const { deleteZoneRequest, deleteZoneSuccess, deleteZoneFailure, deleteZoneDefault } = deleteZoneSlice.actions;

export default deleteZoneSlice.reducer;
