import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  successUpdate: false,
  error: '',
};

export const updateAppLocalizationSlice = createSlice({
  name: 'appLocalization/updateAppLocalization',
  initialState,
  reducers: {
    updateAppLocalizationRequest: (state) => {
      state.loading = true;
      state.successUpdate = false;
      state.errorUpdate = '';
    },
    updateAppLocalizationSuccess: (state) => {
      state.successUpdate = true;
      state.loading = false;
    },
    updateAppLocalizationFailure: (state, action) => {
      state.loading = false;
      state.errorUpdate = action.payload;
    },
    updateAppLocalizationDefault: (state, action) => {
      state.loading = false;
      state.successUpdate = false;
      state.errorUpdate = '';
    },
  },
});

export const {
  updateAppLocalizationRequest,
  updateAppLocalizationSuccess,
  updateAppLocalizationFailure,
  updateAppLocalizationDefault,
} = updateAppLocalizationSlice.actions;

export default updateAppLocalizationSlice.reducer;
