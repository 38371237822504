import { useSelector, useDispatch } from 'react-redux';
import { setUser, initialState } from 'store/auth/userSlice';
import { apiSignOut, apiSignUp } from 'services/AuthService';
import { onSignOutSuccess } from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import { REDIRECT_URL_KEY } from 'constants/app.constant';
import { useNavigate } from 'react-router-dom';
import useQuery from './useQuery';
import {
  getAllSubAccountFailure,
  getAllSubAccountRequest,
  getAllSubAccountSuccess,
  getProfileDefault,
  getProfileFailure,
  getProfileRequest,
  getProfileSuccess,
} from 'src/store/auth/adminSlice';
import { apiGetProfileAdmin, apiRefreshToken, apiSignIn, apiSwitchSubaccount } from 'src/services/AuthService';
import { onSignInSuccess } from 'src/store/auth/sessionSlice';
import {
  switchSubAccountFailure,
  switchSubAccountRequest,
  switchSubAccountSuccess,
} from 'src/store/auth/subAccountSwitchSlice';
import { RES_SUCCESS } from 'src/constants/api.constant';
import { refreshTokenFailure, refreshTokenRequest, refreshTokenSuccess } from 'src/store/auth/refreshTokenSlice';
import { LOCAL_STORAGE_SUB_ACCOUNT } from 'src/constants/common.constant';

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  // const { token, signedIn } = useSelector((state) => state.auth.session);
  let authToken = localStorage.getItem('authToken') ? JSON.parse(localStorage.getItem('authToken')) : null;

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data) {
        const data = resp.data;
        // dispatch(onSignInSuccess(data));
        localStorage.setItem(
          'authToken',
          JSON.stringify({ accessToken: data.accessToken, refreshToken: data.refreshToken, signedIn: true })
        );
        localStorage.setItem('role', JSON.stringify(data?.role));
        localStorage.setItem(
          LOCAL_STORAGE_SUB_ACCOUNT,
          JSON.stringify({
            id: data?.subAccountId,
            title: data?.title,
            code: data?.code,
            isCompanySubAccount: data?.isCompanySubAccount,
          })
        );

        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: '',
                userName: 'Anonymous',
                authority: ['USER'],
                email: '',
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: 'success',
          message: '',
        };
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const data = resp.data;
        // dispatch(onSignInSuccess(token));
        localStorage.setItem(
          'authToken',
          JSON.stringify({ accessToken: data.accessToken, refreshToken: data.refreshToken, signedIn: true })
        );
        localStorage.setItem('role', JSON.stringify(data?.role));

        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: '',
                userName: 'Anonymous',
                authority: ['USER'],
                email: '',
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: 'success',
          message: '',
        };
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    dispatch(getProfileDefault());
    navigate(appConfig.unAuthenticatedEntryPath);
    localStorage.setItem('authToken', JSON.stringify({ accessToken: '', refreshToken: '', signedIn: false }));
    localStorage.setItem('role', JSON.stringify(''));
    localStorage.setItem('subAccount', JSON.stringify(''));
  };

  const signOut = async () => {
    handleSignOut();
  };

  const getAllSubAccount = async (values) => {
    try {
      dispatch(getAllSubAccountRequest());
      const resp = await apiGetProfileAdmin(values);

      if (resp.data) {
        const data = resp?.data;
        dispatch(getAllSubAccountSuccess(data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(getAllSubAccountFailure(error));
    }
  };

  const getProfileAdmin = async (values) => {
    try {
      dispatch(getProfileRequest());
      const resp = await apiGetProfileAdmin(values);

      if (resp.data) {
        const data = resp?.data;
        dispatch(getProfileSuccess(data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(getProfileFailure(error));
    }
  };

  const switchSubaccount = async (values) => {
    try {
      dispatch(switchSubAccountRequest());
      const resp = await apiSwitchSubaccount(values);
      if (resp.status === RES_SUCCESS) {
        const data = resp.data;
        dispatch(switchSubAccountSuccess(data));
        // dispatch(onSignInSuccess(data));
        localStorage.setItem(
          'authToken',
          JSON.stringify({ accessToken: data.accessToken, refreshToken: data.refreshToken, signedIn: true })
        );
        localStorage.setItem(LOCAL_STORAGE_SUB_ACCOUNT, JSON.stringify(data?.dataSubAccount));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(switchSubAccountFailure(error));
    }
  };

  const refreshToken = async (values) => {
    try {
      dispatch(refreshTokenRequest());
      const resp = await apiRefreshToken(values);
      if (resp.status === RES_SUCCESS) {
        const data = resp.data;
        dispatch(onSignInSuccess(data));
        dispatch(refreshTokenSuccess());
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(refreshTokenFailure(error));
    }
  };

  return {
    authenticated: authToken?.accessToken && authToken?.signedIn,
    signIn,
    signUp,
    signOut,
    getAllSubAccount,
    getProfileAdmin,
    switchSubaccount,
    refreshToken,
  };
}

export default useAuth;
