import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
  resultEndRides: {},
};

export const endRideSlice = createSlice({
  name: 'ride/endRide',
  initialState,
  reducers: {
    endRideSliceRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
    },
    endRideSliceSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload.message;
      state.loading = false;
      state.resultEndRides = payload.data;
    },
    endRideSliceFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    endRideSliceDefault: (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = '';
      state.message = '';
      state.resultEndRides = {};
    },
  },
});

export const { endRideSliceDefault, endRideSliceFailure, endRideSliceRequest, endRideSliceSuccess } =
  endRideSlice.actions;

export default endRideSlice.reducer;
