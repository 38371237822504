import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  pagination: {},
  error: '',
  message: {},
  data: [],
};

export const getTransactionSlice = createSlice({
  name: 'financeManagement/getTransaction',
  initialState,
  reducers: {
    getTransactionRequest: (state) => {
      state.loading = true;
    },
    getTransactionSuccess: (state, action) => {
      state.loading = false;
      state.pagination = action.payload?.pagination;
      state.data = action.payload?.responses;
    },
    getTransactionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getTransactionFailure, getTransactionRequest, getTransactionSuccess } = getTransactionSlice.actions;

export default getTransactionSlice.reducer;
