import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  customer: {},
};

export const getCustomerByIdSlice = createSlice({
  name: 'customer/getCustomerById',
  initialState,
  reducers: {
    getCustomerByIdRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.customer = {};
    },
    getCustomerByIdSuccess: (state, { payload }) => {
      state.success = true;
      state.customer = payload;
      state.loading = false;
    },
    getCustomerByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getCustomerByIdRequest, getCustomerByIdSuccess, getCustomerByIdFailure, getCustomerByIdDefault } =
  getCustomerByIdSlice.actions;

export default getCustomerByIdSlice.reducer;
