import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
};

export const createCustomerSlice = createSlice({
  name: 'customer/createCustomer',
  initialState,
  reducers: {
    onCreateCustomerRequest: (state) => {
      state.loading = true;
    },
    onCreateCustomerSuccess: (state) => {
      state.success = true;
      state.loading = false;
    },
    onCreateCustomerFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { onCreateCustomerRequest, onCreateCustomerSuccess, onCreateCustomerFailure } =
  createCustomerSlice.actions;

export default createCustomerSlice.reducer;
