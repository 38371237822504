import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: false,
};

export const callCommandVehicleSlice = createSlice({
  name: 'perform-command',
  initialState,
  reducers: {
    callCommandVehicleRequest: (state) => {
      state.loading = true;
    },
    callCommandVehicleSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
    },
    callCommandVehicleFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    callCommandVehicleDefault: (state) => {
      state.loading = initialState.loading;
      state.success = initialState.success;
      state.error = initialState.error;
    },
  },
});

export const {
  callCommandVehicleRequest,
  callCommandVehicleSuccess,
  callCommandVehicleFailure,
  callCommandVehicleDefault,
} = callCommandVehicleSlice.actions;

export default callCommandVehicleSlice.reducer;
