import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const deleteVehicleByIdSlice = createSlice({
  name: 'vehicle/deleteVehicleById',
  initialState,
  reducers: {
    deleteVehicleByIdRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
      state.error = '';
    },
    deleteVehicleByIdSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload;
      state.loading = false;
    },
    deleteVehicleByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteVehicleByIdDefault: (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const {
  deleteVehicleByIdRequest,
  deleteVehicleByIdSuccess,
  deleteVehicleByIdFailure,
  deleteVehicleByIdDefault,
} = deleteVehicleByIdSlice.actions;

export default deleteVehicleByIdSlice.reducer;
