import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
};

export const createPolygonSlice = createSlice({
  name: 'zones/createPolygon',
  initialState,
  reducers: {
    onCreatePolygonRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
    },
    onCreatePolygonSuccess: (state) => {
      state.success = true;
      state.loading = false;
    },
    onCreatePolygonFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    onCreatePolygonDefault: (state) => {
      state.loading = false;
      state.success = false;
      state.error = '';
    },
  },
});

export const { onCreatePolygonRequest, onCreatePolygonSuccess, onCreatePolygonFailure, onCreatePolygonDefault } =
  createPolygonSlice.actions;

export default createPolygonSlice.reducer;
