import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: {},
};

export const deletePromoSlice = createSlice({
  name: 'marketing/deletePromoMarketing',
  initialState,
  reducers: {
    deletePromoRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    deletePromoSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload;
      state.loading = false;
    },
    deletePromoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deletePromoDefault: (state) => {
      state.loading = false;
      state.success = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const { deletePromoRequest, deletePromoSuccess, deletePromoFailure, deletePromoDefault } =
  deletePromoSlice.actions;

export default deletePromoSlice.reducer;
