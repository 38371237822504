import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  subaccount: {},
};

export const detailSubaccountSlice = createSlice({
  name: 'subAccount/detailSubaccount',
  initialState,
  reducers: {
    getSubaccountByIdRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.subaccount = {};
    },
    getSubaccountByIdSuccess: (state, { payload }) => {
      state.success = true;
      state.subaccount = payload;
      state.loading = false;
    },
    getSubaccountByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getSubaccountByIdDefault: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = '';
      state.subaccount = {};
    },
  },
});

export const {
  getSubaccountByIdRequest,
  getSubaccountByIdSuccess,
  getSubaccountByIdFailure,
  getSubaccountByIdDefault,
} = detailSubaccountSlice.actions;

export default detailSubaccountSlice.reducer;
