import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  alert: [],
  loading: false,
  error: '',
  pagination: {},
};

export const getListSlice = createSlice({
  name: 'alertNotification/getList',
  initialState,
  reducers: {
    getAllAlertRequest: (state) => {
      state.loading = true;
    },
    getAllAlertSuccess: (state, action) => {
      state.alert = action.payload?.responses;
      state.pagination = action.payload?.pagination;
      state.loading = false;
    },
    getAllAlertFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getAllAlertFailure, getAllAlertRequest, getAllAlertSuccess } = getListSlice.actions;

export default getListSlice.reducer;
