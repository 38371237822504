import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: '',
};

export const deletePolygonSlice = createSlice({
  name: 'zones/deletePolygon',
  initialState,
  reducers: {
    deletePolygonRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    deletePolygonSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
    },
    deletePolygonFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deletePolygonDefault: (state) => {
      state.loading = false;
      state.error = '';
      state.success = false;
      state.message = '';
    },
  },
});

export const { deletePolygonRequest, deletePolygonSuccess, deletePolygonFailure, deletePolygonDefault } =
  deletePolygonSlice.actions;

export default deletePolygonSlice.reducer;
