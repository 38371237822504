import { Notification, toast } from 'src/components/ui';

export const getTimeAgo = (date) => {
  const currentTime = Date.now();
  const differenceTime = Math.floor((currentTime - new Date(date).getTime()) / 1000);

  if (differenceTime >= 0 && differenceTime <= 59) return `${Math.floor(differenceTime / 60)} min`;

  if (differenceTime >= 60 && differenceTime <= 60 * 60 - 1) return `${Math.floor(differenceTime / 60)} mins`;

  if (differenceTime >= 60 * 60 && differenceTime <= 60 * 60 * 2 - 1)
    return `${Math.floor(differenceTime / (60 * 60))} hour`;

  if (differenceTime >= 60 * 60 * 2 && differenceTime <= 60 * 60 * 24 - 1)
    return `${Math.floor(differenceTime / (60 * 60))} hours`;

  if (differenceTime >= 60 * 60 * 24 && differenceTime <= 60 * 60 * 24 * 2 - 1)
    return `${Math.floor(differenceTime / (60 * 60 * 24))} day`;

  if (differenceTime >= 60 * 60 * 24 * 2 && differenceTime <= 60 * 60 * 24 * 30 - 1)
    return `${Math.floor(differenceTime / (60 * 60 * 24))} days`;
};

export const getFullDate = (date) => {
  const dateConvert = new Date(date);
  const day = dateConvert.getDate() < 10 ? `0${dateConvert.getDate()}` : dateConvert.getDate();
  const month = dateConvert.getMonth() + 1 < 10 ? `0${dateConvert.getMonth() + 1}` : dateConvert.getMonth() + 1;
  const year = `${dateConvert.getFullYear()}`;
  return day + '.' + month + '.' + year + '.';
};

export const getFullTime = (date) => {
  const dateConvert = new Date(date);
  const hours = dateConvert.getHours() < 10 ? `0${dateConvert.getHours()}` : dateConvert.getHours();
  const minute = dateConvert.getMinutes() < 10 ? `0${dateConvert.getMinutes()}` : dateConvert.getMinutes();
  const second = dateConvert.getSeconds() < 10 ? `0${dateConvert.getSeconds()}` : dateConvert.getSeconds();
  return hours + ':' + minute + ':' + second;
};

export const convertDuration = (duration) => {
  const totalMinutes = Math.floor(duration / 60);

  const seconds = duration % 60 < 10 ? `0${duration % 60}` : duration % 60;
  const hours =
    Math.floor(totalMinutes / 60) < 10 ? `0${Math.floor(totalMinutes / 60)}` : Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60 < 10 ? `0${totalMinutes % 60}` : totalMinutes % 60;

  return hours + ':' + minutes + ':' + seconds;
};

export const openNotification = (title, type, placement) => {
  toast.push(<Notification type={type} title={title} />, {
    placement: placement || 'top-center',
  });
};

export const toUpperCaseFirstChar = (string) => {
  const lower = string.toLowerCase();
  return lower.charAt(0).toUpperCase() + lower.slice(1);
};

export const convertToMinutes = (time) => {
  // const minutes = Math.floor(time / 60) <= 1 ? `${Math.floor(time / 60)} min` : `${Math.floor(time / 60)} mins`;
  // return minutes;

  const durationPause = time > 0 ? time : 0;

  const totalPauseMinute =
    durationPause % 60 === 0 ? `${durationPause / 60} min` : `${Math.floor(durationPause / 60) + 1} min`;
  return totalPauseMinute;
};

export const getWeekNowDate = () => {
  const curr = new Date();

  const firstDay = new Date(curr.setDate(curr.getDate() - 7));
  const lastDay = new Date();

  return { firstDay, lastDay };
};

export const compare = (a, b) => {
  if (parseFloat(a.id) < parseFloat(b.id)) {
    return -1;
  }
  if (parseFloat(a.id) > parseFloat(b.id)) {
    return 1;
  }
  return 0;
};

export const getFullDateForExport = (date) => {
  const dateConvert = new Date(date);
  const day = dateConvert.getDate() < 10 ? `0${dateConvert.getDate()}` : dateConvert.getDate();
  const month = dateConvert.getMonth() + 1 < 10 ? `0${dateConvert.getMonth() + 1}` : dateConvert.getMonth() + 1;
  const year = `${dateConvert.getFullYear()}`;
  return year + '-' + month + '-' + day;
};

export const limitPlaceOnDecimal = (num, limit) => {
  if (String(num).includes('.')) {
    return String(num).split('.')[1]?.length <= limit;
  } else {
    return true;
  }
};

export const limitInputNumber = (num, min, max) => {
  return num >= min && num <= max;
};
