import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  activity: [],
  pagination: {},
  loading: false,
  error: '',
};

export const getVehicleActivitySlice = createSlice({
  name: 'vehicle/getVehicleActivity',
  initialState,
  reducers: {
    getListVehicleActivityRequest: (state) => {
      state.loading = true;
    },
    getListVehicleActivitySuccess: (state, action) => {
      state.activity = action.payload?.responses;
      state.pagination = action.payload?.pagination;
      state.loading = false;
    },
    getListVehicleActivityFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getListVehicleActivityRequest, getListVehicleActivitySuccess, getListVehicleActivityFailure } =
  getVehicleActivitySlice.actions;

export default getVehicleActivitySlice.reducer;
