import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  value: '',
};

export const searchFromSidenavSlice = createSlice({
  name: 'base/searchFromSidenav',
  initialState,
  reducers: {
    setCurrentSearch: (state, action) => {
      state.value = action.payload;
    },
    setCurrentSearchEmpty: (state) => {
      state.value = '';
    },
  },
});

export const { setCurrentSearchEmpty, setCurrentSearch } = searchFromSidenavSlice.actions;

export default searchFromSidenavSlice.reducer;
