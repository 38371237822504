import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  errorUpdate: '',
  qrCodeVehicle: '',
  message: '',
};
export const downloadQrVehicleByIdSlice = createSlice({
  name: 'vehicle/downloadQrVehicle',
  initialState,
  reducers: {
    downloadQrVehicleRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.errorUpdate = '';
    },
    downloadQrVehicleSuccess: (state, { payload }) => {
      state.qrCodeVehicle = payload.data.linkQR;
      state.success = true;
      state.loading = false;
      state.message = payload.message;
    },
    downloadQrVehicleFailure: (state, action) => {
      state.loading = false;
      state.errorUpdate = action.payload;
      state.message = action.payload.message;
    },
    downloadQrVehicleDefault: (state, action) => {
      state.loading = false;
      state.success = false;
      state.errorUpdate = '';
    },
  },
});

export const {
  downloadQrVehicleRequest,
  downloadQrVehicleSuccess,
  downloadQrVehicleFailure,
  downloadQrVehicleDefault,
} = downloadQrVehicleByIdSlice.actions;

export default downloadQrVehicleByIdSlice.reducer;
