import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
};

export const createIoTSlice = createSlice({
  name: 'iot/createIoT',
  initialState,
  reducers: {
    createIoTRequest: (state) => {
      state.loading = true;
      state.error = '';
      state.success = false;
    },
    createIoTDefault: (state) => {
      state.loading = false;
      state.error = '';
      state.success = false;
    },
    createIoTSuccess: (state) => {
      state.success = true;
      state.loading = false;
    },
    createIoTFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { createIoTRequest, createIoTSuccess, createIoTFailure, createIoTDefault } = createIoTSlice.actions;

export default createIoTSlice.reducer;
