import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  heatMap: [],
  loading: false,
  error: '',
};

export const getListHeatMapSlice = createSlice({
  name: 'analytic/getListHeatMap',
  initialState,
  reducers: {
    getListHeatMapRequest: (state) => {
      state.loading = true;
    },
    getListHeatMapSuccess: (state, action) => {
      state.heatMap = action.payload;
      state.loading = false;
    },
    getListHeatMapFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getListHeatMapFailure, getListHeatMapRequest, getListHeatMapSuccess } = getListHeatMapSlice.actions;

export default getListHeatMapSlice.reducer;
