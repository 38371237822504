import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  customerGroup: [],
  success: false,
  loading: false,
  error: '',
  message: {},
  pagination: {},
};

export const getCustomerGroupByIdSlice = createSlice({
  name: 'customerGroup/getCustomerGroupById',
  initialState,
  reducers: {
    getCustomerGroupByIdRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
      state.group = {};
    },
    getCustomerGroupByIdSuccess: (state, { payload }) => {
      state.customerGroup = payload?.responses;
      state.pagination = payload?.pagination;
      state.success = true;
      state.message = payload;
      state.loading = false;
    },
    getCustomerGroupByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getCustomerGroupByIdRequest,
  getCustomerGroupByIdSuccess,
  getCustomerGroupByIdFailure,
  getCustomerGroupByIdDefault,
} = getCustomerGroupByIdSlice.actions;

export default getCustomerGroupByIdSlice.reducer;
