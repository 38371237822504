import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  value: {},
};

export const createEndTheRideSlice = createSlice({
  name: 'systemPreference/createEndTheRide',
  initialState,
  reducers: {
    createEndTheRideRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.value = '';
    },
    createEndTheRideSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.value = action.payload;
    },
    createEndTheRideFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createEndTheRideDefault: (state) => {
      state.loading = initialState?.loading;
      state.success = initialState?.success;
      state.error = initialState?.error;
      state.value = initialState?.value;
    },
  },
});

export const { createEndTheRideDefault, createEndTheRideFailure, createEndTheRideRequest, createEndTheRideSuccess } =
  createEndTheRideSlice.actions;

export default createEndTheRideSlice.reducer;
