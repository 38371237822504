const appConfig = {
  apiPrefix: process.env.REACT_APP_BASE_URL,
  authenticatedEntryPath: '/dashboard',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  enableMock: false,
  environment: process.env.REACT_APP_NODE_ENV,
  isProduction: process.env.REACT_APP_NODE_ENV === 'production' ? true : false,
};

export default appConfig;
