import { useDispatch } from 'react-redux';
import {
  apiCreatePolygon,
  apiCreateZone,
  apiDeletePolygonById,
  apiDeleteZoneById,
  apiGetAllPolygon,
  apiGetAllZone,
  apiGetZoneById,
  apiUpdateDescZone,
  apiUpdatePolygonById,
  apiUpdateZoneById,
} from 'src/services/ZoneService';
import { onCreateZoneFailure, onCreateZoneRequest, onCreateZoneSuccess } from 'src/store/more/zones/createZoneSlice';
import { onGetListZoneFailure, onGetListZoneRequest, onGetListZoneSuccess } from 'src/store/more/zones/listZoneSlice';
import {
  onCreatePolygonFailure,
  onCreatePolygonRequest,
  onCreatePolygonSuccess,
} from 'src/store/more/zones/createPolygonSlice';
import { onGetZoneByIdFailure, onGetZoneByIdRequest, onGetZoneByIdSuccess } from 'src/store/more/zones/zoneByIdSlice';
import { onUpdateZoneFailure, onUpdateZoneRequest, onUpdateZoneSuccess } from 'src/store/more/zones/updateZoneSlice';
import { deleteZoneFailure, deleteZoneRequest, deleteZoneSuccess } from 'src/store/more/zones/deleteZoneSlice';
import {
  deletePolygonFailure,
  deletePolygonRequest,
  deletePolygonSuccess,
} from 'src/store/more/zones/deletePolygonSlice';
import {
  updatePolygonFailure,
  updatePolygonRequest,
  updatePolygonSuccess,
} from 'src/store/more/zones/updatePolygonSlice';
import {
  getListPolygonFailure,
  getListPolygonRequest,
  getListPolygonSuccess,
} from 'src/store/more/zones/listPolygonSlice';
import { RES_SUCCESS } from 'src/constants/api.constant';
import {
  updateDescZoneFailure,
  updateDescZoneRequest,
  updateDescZoneSuccess,
} from 'src/store/more/zones/updateDescZoneSlice';
function useZones() {
  const dispatch = useDispatch();

  const postNewZone = async (values) => {
    try {
      dispatch(onCreateZoneRequest());
      const resp = await apiCreateZone(values);
      if (resp) {
        dispatch(onCreateZoneSuccess());
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(onCreateZoneFailure(error));
    }
  };

  const getAllZone = async (values) => {
    try {
      dispatch(onGetListZoneRequest());
      const resp = await apiGetAllZone(values);
      if (resp) {
        dispatch(onGetListZoneSuccess(resp.data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(onGetListZoneFailure(error));
    }
  };

  const getAllPolygon = async () => {
    try {
      dispatch(getListPolygonRequest());
      const resp = await apiGetAllPolygon();
      if (resp.data) {
        dispatch(getListPolygonSuccess(resp.data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(getListPolygonFailure(error));
    }
  };

  const postNewPolygon = async (values) => {
    try {
      dispatch(onCreatePolygonRequest());
      const resp = await apiCreatePolygon(values);
      if (resp) {
        dispatch(onCreatePolygonSuccess());
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(onCreatePolygonFailure(error));
    }
  };

  const getZoneById = async (values) => {
    try {
      dispatch(onGetZoneByIdRequest());
      const resp = await apiGetZoneById(values);
      if (resp.data) {
        dispatch(onGetZoneByIdSuccess(resp.data));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(onGetZoneByIdFailure(error));
    }
  };

  const updateZoneById = async (values) => {
    try {
      dispatch(onUpdateZoneRequest());

      const resp = await apiUpdateZoneById({
        id: values.id,
        data: values.data,
      });
      if (resp.status === RES_SUCCESS) {
        dispatch(onUpdateZoneSuccess(resp.message));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(onUpdateZoneFailure(error));
    }
  };

  const updatePolygonById = async (values) => {
    try {
      dispatch(updatePolygonRequest());
      const resp = await apiUpdatePolygonById({
        id: values.id,
        data: {
          type: 'Polygon',
          coordinates: values?.coordinates,
        },
      });
      if (resp.status === RES_SUCCESS) {
        dispatch(updatePolygonSuccess(resp.message));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(updatePolygonFailure(error));
    }
  };

  const updateSettingZone = async (values) => {
    try {
      dispatch(updateDescZoneRequest());
      const resp = await apiUpdateDescZone(values);
      if (resp.status === RES_SUCCESS) {
        dispatch(updateDescZoneSuccess(resp.message));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(updateDescZoneFailure(error));
    }
  };

  const deleteZoneById = async ({ id }) => {
    try {
      dispatch(deleteZoneRequest());

      const resp = await apiDeleteZoneById({
        id,
      });
      if (resp.status === RES_SUCCESS) {
        dispatch(deleteZoneSuccess(resp.message));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(deleteZoneFailure(error));
    }
  };

  const deletePolygonById = async ({ id }) => {
    try {
      dispatch(deletePolygonRequest());

      const resp = await apiDeletePolygonById({
        id,
      });
      if (resp.status === RES_SUCCESS) {
        dispatch(deletePolygonSuccess(resp.message));
      }
    } catch (errors) {
      const error = errors?.response?.data?.message || errors.toString();
      dispatch(deletePolygonFailure(error));
    }
  };

  return {
    postNewZone,
    getAllZone,
    postNewPolygon,
    getZoneById,
    updateZoneById,
    deleteZoneById,
    deletePolygonById,
    updatePolygonById,
    getAllPolygon,
    updateSettingZone,
  };
}

export default useZones;
