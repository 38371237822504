import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  documents: [],
};

export const getDocumentVehicleSlice = createSlice({
  name: 'vehicle/getDocumentVehicle',
  initialState,
  reducers: {
    getDocumentVehicleRequest: (state) => {
      state.loading = true;
      state.documents = [];
    },
    getDocumentVehicleSuccess: (state, { payload }) => {
      state.documents = payload.vehicleDocument;
      state.loading = false;
    },
    getDocumentVehicleFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getDocumentVehicleDefault: (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = '';
      state.documents = [];
    },
  },
});

export const {
  getDocumentVehicleRequest,
  getDocumentVehicleSuccess,
  getDocumentVehicleFailure,
  getDocumentVehicleDefault,
} = getDocumentVehicleSlice.actions;

export default getDocumentVehicleSlice.reducer;
