import { createSlice } from '@reduxjs/toolkit';
import { compare } from 'src/utils/helper';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: {},
  data: [],
};

export const getTermAndConditionSlice = createSlice({
  name: 'settings/system-reference',
  initialState,
  reducers: {
    getTermAndConditionRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    getTermAndConditionSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      state.data = action.payload.sort(compare);
    },
    getTermAndConditionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getTermAndConditionDefault: (state) => {
      state.loading = initialState.loading;
      state.success = initialState.success;
      state.error = initialState.error;
      state.message = initialState.message;
    },
  },
});

export const {
  getTermAndConditionRequest,
  getTermAndConditionSuccess,
  getTermAndConditionFailure,
  getTermAndConditionDefault,
} = getTermAndConditionSlice.actions;

export default getTermAndConditionSlice.reducer;
