import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: true,
  error: '',
  statistic: {},
};

export const getStatisticSlice = createSlice({
  name: 'dashboard/getStatistic',
  initialState,
  reducers: {
    getStatisticRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
    },
    getStatisticSuccess: (state, { payload }) => {
      state.success = true;
      state.statistic = payload;
      state.loading = false;
    },
    getStatisticFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getStatisticRequest, getStatisticSuccess, getStatisticFailure, getStatisticDefault } =
  getStatisticSlice.actions;

export default getStatisticSlice.reducer;
