import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: {},
};

export const newPromoSlice = createSlice({
  name: 'marketing/newPromo',
  initialState,
  reducers: {
    newPromoRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    newPromoSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload;
      state.loading = false;
    },
    newPromoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    newPromoDefault: (state) => {
      state.loading = false;
      state.success = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const { newPromoRequest, newPromoSuccess, newPromoFailure, newPromoDefault } = newPromoSlice.actions;

export default newPromoSlice.reducer;
