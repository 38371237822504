import { createSlice } from '@reduxjs/toolkit';
import { compare } from 'src/utils/helper';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: {},
  data: [],
};

export const getPrivacyPolicySlice = createSlice({
  name: 'settings/system-reference',
  initialState,
  reducers: {
    getPrivacyPolicyRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    getPrivacyPolicySuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      state.data = action.payload.sort(compare);
    },
    getPrivacyPolicyFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getPrivacyPolicyDefault: (state) => {
      state.loading = initialState.loading;
      state.success = initialState.success;
      state.error = initialState.error;
      state.message = initialState.message;
    },
  },
});

export const { getPrivacyPolicyRequest, getPrivacyPolicySuccess, getPrivacyPolicyFailure, getPrivacyPolicyDefault } =
  getPrivacyPolicySlice.actions;

export default getPrivacyPolicySlice.reducer;
