import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  vehicle: {},
  vehicleDocument: [],
};

export const getVehicleByIdSlice = createSlice({
  name: 'vehicle/getVehicleById',
  initialState,
  reducers: {
    getVehicleByIdRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.vehicle = {};
    },
    getVehicleByIdSuccess: (state, { payload }) => {
      state.success = true;
      state.vehicle = payload;
      state.vehicleDocument = payload.vehicleDocument;
      state.loading = false;
    },
    getVehicleByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getVehicleByIdRequest, getVehicleByIdSuccess, getVehicleByIdFailure, getVehicleByIdDefault } =
  getVehicleByIdSlice.actions;

export default getVehicleByIdSlice.reducer;
