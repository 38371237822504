import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  activity: [],
  loading: false,
  error: '',
};

export const getActivityRideSlice = createSlice({
  name: 'ride/activityRide',
  initialState,
  reducers: {
    getActivityRideRequest: (state) => {
      state.loading = true;
      state.activity = [];
      state.error = '';
    },
    getActivityRideSuccess: (state, action) => {
      state.activity = action.payload;
      state.loading = false;
    },
    getActivityRideFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getActivityRideDefault: (state) => {
      state.loading = false;
      state.error = '';
      state.activity = [];
    },
  },
});

export const { getActivityRideRequest, getActivityRideSuccess, getActivityRideFailure, getActivityRideDefault } =
  getActivityRideSlice.actions;

export default getActivityRideSlice.reducer;
