import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  customersSearch: [],
  loading: false,
  error: '',
};

export const listCustomerSlice = createSlice({
  name: 'customer/listCustomerSearch',
  initialState,
  reducers: {
    getListCustomerSearchRequest: (state) => {
      state.loading = true;
    },
    getListCustomerSearchSuccess: (state, action) => {
      state.customersSearch = action.payload;
      state.loading = false;
    },
    getListCustomerSearchFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getListCustomerSearchSuccess, getListCustomerSearchRequest, getListCustomerSearchFailure } =
  listCustomerSlice.actions;

export default listCustomerSlice.reducer;
