import ApiService from './ApiService';

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: '/auth/login-admin',
    method: 'post',
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: '/sign-up',
    method: 'post',
    data,
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: '/sign-out',
    method: 'post',
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: '/forgot-password',
    method: 'post',
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: '/reset-password',
    method: 'post',
    data,
  });
}
export async function apiGetProfileAdmin(data) {
  return ApiService.fetchData({
    url: '/admins',
    method: 'get',
    data,
  });
}
export async function apiSwitchSubaccount({ id }) {
  return ApiService.fetchData({
    url: `admins/subaccount/${id}`,
    method: 'put',
  });
}

export async function apiRefreshToken(data) {
  return ApiService.fetchData({
    url: `auth/refresh/`,
    method: 'post',
    data,
  });
}
