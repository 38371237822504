import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Input } from 'components/ui';

const NumberInput = ({ inputSuffix, inputPrefix, ...props }) => {
  return <Input {...props} value={props.field.value} suffix={inputSuffix} prefix={inputPrefix} />;
};

const NumberFormatInput = ({ onValueChange, form, field, decimalScale, withAllowValue, ...rest }) => {
  return (
    <NumberFormat
      customInput={NumberInput}
      onValueChange={onValueChange}
      withallowvalue={withAllowValue}
      form={form}
      field={field}
      onBlur={field?.onBlur}
      decimalScale={decimalScale}
      {...rest}
    />
  );
};

const FormNumericInput = ({
  form,
  field,
  inputSuffix,
  inputPrefix,
  onValueChange,
  decimalScale,
  withAllowValue,
  ...rest
}) => {
  return (
    <NumberFormatInput
      form={form}
      field={field}
      inputPrefix={inputPrefix}
      inputSuffix={inputSuffix}
      decimalScale={decimalScale}
      onValueChange={onValueChange}
      isAllowed={withAllowValue}
      {...rest}
    />
  );
};

FormNumericInput.propTypes = {
  form: PropTypes.object,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }),
  inputSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  inputPrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  decimalScale: PropTypes.number,
  withAllowValue: PropTypes.func,
};

export default FormNumericInput;
