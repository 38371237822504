import { combineReducers } from '@reduxjs/toolkit';
import common from './commonSlice';
import searchFromSidenav from './searchFromSidenavSlice';
import sort from './sortSlice';
const reducer = combineReducers({
  common,
  searchFromSidenav,
  sort,
});

export default reducer;
