import { combineReducers } from '@reduxjs/toolkit';
import listRideSelected from './listRideSelectedSlice';
import listRide from './listRideSlice';
import getRideDetail from './getRideDetailSlice.js';
import activityRide from './getActivityRideSlice.js';
import endRide from './enRideByAdminSlice.js';
import refundRide from './refundRideSlice.js';
import historyRide from './historyRideSlice';

const reducer = combineReducers({
  listRideSelected,
  listRide,
  getRideDetail,
  activityRide,
  endRide,
  refundRide,
  historyRide,
});

export default reducer;
