import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const importVehicleSlice = createSlice({
  name: 'vehicle/importVehicle',
  initialState,
  reducers: {
    importVehicleRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    importVehicleSuccess: (state, action) => {
      state.success = true;
      state.message = action.payload;
      state.loading = false;
    },
    importVehicleFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    importVehicleDefault: (state) => {
      state.loading = false;
      state.success = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const { importVehicleSuccess, importVehicleRequest, importVehicleFailure, importVehicleDefault } =
  importVehicleSlice.actions;

export default importVehicleSlice.reducer;
