import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const updateSubaccountSlice = createSlice({
  name: 'subAccount/updateSubaccount',
  initialState,
  reducers: {
    updateSubaccountRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
      state.error = '';
    },
    updateSubaccountSuccess: (state, { payload }) => {
      state.success = true;
      state.loading = false;
      state.message = payload;
    },
    updateSubaccountFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateSubaccountDefault: (state) => {
      state.loading = false;
      state.success = false;
      state.message = '';
      state.error = '';
    },
  },
});

export const { updateSubaccountRequest, updateSubaccountSuccess, updateSubaccountFailure, updateSubaccountDefault } =
  updateSubaccountSlice.actions;

export default updateSubaccountSlice.reducer;
