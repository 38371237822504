import { combineReducers } from '@reduxjs/toolkit';
import importFileLanguage from './importFileLanguage';
import listAppLocalization from './listAppLocalizationSlice';
import updateAppLocalization from './updateAppLocalizationSlice';
import createAppLocalization from './createAppLocalizationSlice';
const reducer = combineReducers({
  importFileLanguage,
  listAppLocalization,
  updateAppLocalization,
  createAppLocalization,
});

export default reducer;
