import { createSlice } from '@reduxjs/toolkit';

export const listUserSlice = createSlice({
  name: 'auth/listUser',
  initialState: {
    users: [],
    loading: false,
    success: false,
    pagination: {},
  },
  reducers: {
    getAllUserRequest: (state) => {
      state.loading = true;
      state.users = [];
      state.error = '';
      state.success = false;
    },
    getAllUserSuccess: (state, action) => {
      state.users = action.payload?.responses;
      state.pagination = action.payload?.pagination;
      state.success = true;
      state.loading = false;
    },
    getAllUserFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    getAllUserDefault: (state, action) => {
      state.loading = false;
      state.users = [];
      state.error = '';
      state.success = false;
    },
  },
});

export const { getAllUserRequest, getAllUserSuccess, getAllUserFailure, getAllUserDefault } = listUserSlice.actions;

export default listUserSlice.reducer;
