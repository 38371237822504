import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  customers: [],
  pagination: {},
  loading: false,
  error: '',
};

export const listCustomerSlice = createSlice({
  name: 'customer/listCustomer',
  initialState,
  reducers: {
    getListCustomerRequest: (state) => {
      state.loading = true;
    },
    getListCustomerSuccess: (state, action) => {
      state.customers = action.payload?.responses;
      state.pagination = action.payload?.pagination;
      state.loading = false;
    },
    getListCustomerFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getListCustomerSuccess, getListCustomerRequest, getListCustomerFailure } = listCustomerSlice.actions;

export default listCustomerSlice.reducer;
