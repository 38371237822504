import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  value: {},
};

export const createRatioPayoutSlice = createSlice({
  name: 'systemPreference/createRatioPayout',
  initialState,
  reducers: {
    createRatioPayoutRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.value = '';
    },
    createRatioPayoutSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.value = action.payload;
    },
    createRatioPayoutFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createRatioPayoutDefault: (state) => {
      state.loading = initialState?.loading;
      state.success = initialState?.success;
      state.error = initialState?.error;
      state.value = initialState?.value;
    },
  },
});

export const {
  createRatioPayoutDefault,
  createRatioPayoutFailure,
  createRatioPayoutRequest,
  createRatioPayoutSuccess,
} = createRatioPayoutSlice.actions;

export default createRatioPayoutSlice.reducer;
