import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: {},
};

export const createPriceForRideSlice = createSlice({
  name: 'createPriceForRide',
  initialState,
  reducers: {
    createPriceForRideRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    createPriceForRideSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
    },
    createPriceForRideFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createPriceForRideDefault: (state) => {
      state.loading = initialState?.loading;
      state.success = initialState?.success;
      state.error = initialState?.error;
      state.message = initialState?.message;
    },
  },
});

export const {
  createPriceForRideRequest,
  createPriceForRideSuccess,
  createPriceForRideFailure,
  createPriceForRideDefault,
} = createPriceForRideSlice.actions;

export default createPriceForRideSlice.reducer;
