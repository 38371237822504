import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
};

export const createAppLocalizationSlice = createSlice({
  name: 'appLocalization/createAppLocalization',
  initialState,
  reducers: {
    createAppLocalizationRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
    },
    createAppLocalizationSuccess: (state) => {
      state.success = true;
      state.loading = false;
    },
    createAppLocalizationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createAppLocalizationDefault: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = '';
    },
  },
});

export const {
  createAppLocalizationRequest,
  createAppLocalizationSuccess,
  createAppLocalizationFailure,
  createAppLocalizationDefault,
} = createAppLocalizationSlice.actions;

export default createAppLocalizationSlice.reducer;
