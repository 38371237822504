import { combineReducers } from '@reduxjs/toolkit';
import getAllTypeSetting from './getAllTypeSettingSlice';
import getData from './getDataSlice';
import getPriceForRide from './getPriceForRideSlide';
import getPriceForLimitLiability from './getPriceForLimitLiabilitySlice';
import createData from './createDataSlice';
import createPriceForRide from './createPriceForRideSlice';
import createPriceForLimitLiability from './createPriceForLimitLiabilitySlice';

const reducer = combineReducers({
  getAllTypeSetting,
  getData,
  getPriceForRide,
  getPriceForLimitLiability,
  createData,
  createPriceForRide,
  createPriceForLimitLiability,
});

export default reducer;
