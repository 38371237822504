import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  message: {},
};

export const createTermAndConditionSlice = createSlice({
  name: 'Create Term And Condition',
  initialState,
  reducers: {
    createTermAndConditionRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    createTermAndConditionSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
    },
    createTermAndConditionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createTermAndConditionDefault: (state) => {
      state.loading = initialState?.loading;
      state.success = initialState?.success;
      state.error = initialState?.error;
      state.message = initialState?.message;
    },
  },
});

export const {
  createTermAndConditionRequest,
  createTermAndConditionSuccess,
  createTermAndConditionFailure,
  createTermAndConditionDefault,
} = createTermAndConditionSlice.actions;

export default createTermAndConditionSlice.reducer;
