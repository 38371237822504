import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  successUpdate: false,
  loading: false,
  errorUpdate: '',
};
export const updateVehicleByIdSlice = createSlice({
  name: 'vehicle/updateVehicle',
  initialState,
  reducers: {
    onUpdateVehicleRequest: (state) => {
      state.loading = true;
      state.successUpdate = false;
      state.errorUpdate = '';
    },
    onUpdateVehicleSuccess: (state) => {
      state.successUpdate = true;
      state.loading = false;
    },
    onUpdateVehicleFailure: (state, action) => {
      state.loading = false;
      state.errorUpdate = action.payload;
    },
    onUpdateVehicleDefault: (state, action) => {
      state.loading = false;
      state.successUpdate = false;
      state.errorUpdate = '';
    },
  },
});

export const { onUpdateVehicleRequest, onUpdateVehicleSuccess, onUpdateVehicleFailure, onUpdateVehicleDefault } =
  updateVehicleByIdSlice.actions;

export default updateVehicleByIdSlice.reducer;
