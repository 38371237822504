import { createSlice } from '@reduxjs/toolkit';

export const adminSlice = createSlice({
  name: 'auth/admin',
  initialState: {
    token: '',
    signedIn: false,
    subaccounts: [],
    profile: {},
    loading: false,
    success: false,
    currency: '',
    lat: '',
    lng: '',
    subAccountSelected: {},
  },
  reducers: {
    getAllSubAccountRequest: (state) => {
      state.loading = true;
    },
    getAllSubAccountSuccess: (state, action) => {
      state.subaccounts = action.payload?.subaccounts;
      state.loading = false;
    },
    getAllSubAccountFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getProfileRequest: (state) => {
      state.loading = true;
      state.success = false;
    },
    getProfileSuccess: (state, action) => {
      const currentUser = action.payload?.subaccounts?.find((s) => s.isSelected);
      state.profile = action.payload.data;
      state.subaccounts = action.payload?.subaccounts;
      state.subAccountSelected = currentUser;
      state.loading = false;
      state.success = true;
      state.currency = currentUser?.currency;
      state.lat = currentUser?.lat;
      state.lng = currentUser?.lng;
    },
    getProfileFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getProfileDefault: (state, action) => {
      state.loading = false;
      state.error = '';
      state.success = false;
      state.subaccounts = [];
      state.profile = {};
      state.currency = '';
      state.lat = '';
      state.lng = '';
      state.subAccountSelected = {};
    },
  },
});

export const {
  getAllSubAccountRequest,
  getAllSubAccountSuccess,
  getAllSubAccountFailure,
  getProfileSuccess,
  getProfileRequest,
  getProfileFailure,
  getProfileDefault,
} = adminSlice.actions;

export default adminSlice.reducer;
