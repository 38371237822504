import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const importFileLanguageSlice = createSlice({
  name: 'appLocalization/importFileLanguage',
  initialState,
  reducers: {
    importFileLanguageRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    importFileLanguageSuccess: (state, action) => {
      state.success = true;
      state.message = action.payload;
      state.loading = false;
    },
    importFileLanguageFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    importFileLanguageDefault: (state) => {
      state.loading = false;
      state.success = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const {
  importFileLanguageSuccess,
  importFileLanguageRequest,
  importFileLanguageFailure,
  importFileLanguageDefault,
} = importFileLanguageSlice.actions;

export default importFileLanguageSlice.reducer;
