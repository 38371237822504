import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const deleteIoTByIdSlice = createSlice({
  name: 'iot/deleteIoTById',
  initialState,
  reducers: {
    deleteIoTByIdRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
      state.error = '';
    },
    deleteIoTByIdSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload;
      state.loading = false;
    },
    deleteIoTByIdFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteIoTByIdDefault: (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const { deleteIoTByIdRequest, deleteIoTByIdSuccess, deleteIoTByIdFailure, deleteIoTByIdDefault } =
  deleteIoTByIdSlice.actions;

export default deleteIoTByIdSlice.reducer;
