import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
  value: {},
};

export const createRideDistanceSlice = createSlice({
  name: 'systemPreference/createRideDistance',
  initialState,
  reducers: {
    createRideDistanceRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.value = '';
    },
    createRideDistanceSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.value = action.payload;
    },
    createRideDistanceFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createRideDistanceDefault: (state) => {
      state.loading = initialState?.loading;
      state.success = initialState?.success;
      state.error = initialState?.error;
      state.value = initialState?.value;
    },
  },
});

export const {
  createRideDistanceRequest,
  createRideDistanceSuccess,
  createRideDistanceFailure,
  createRideDistanceDefault,
} = createRideDistanceSlice.actions;

export default createRideDistanceSlice.reducer;
