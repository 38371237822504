import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  success: false,
  error: '',
};

export const updateIoTSlice = createSlice({
  name: 'iot/updateIoT',
  initialState,
  reducers: {
    updateIoTRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
    },
    updateIoTSuccess: (state) => {
      state.success = true;
      state.loading = false;
    },
    updateIoTFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateIoTDefault: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = '';
    },
  },
});

export const { updateIoTRequest, updateIoTSuccess, updateIoTFailure, updateIoTDefault } = updateIoTSlice.actions;

export default updateIoTSlice.reducer;
