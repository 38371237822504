import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  ride: {},
  loading: false,
  error: '',
  location: [],
};

export const getRideDetailSlice = createSlice({
  name: 'ride/getRideDetail',
  initialState,
  reducers: {
    getRideDetailRequest: (state) => {
      state.loading = true;
      state.ride = {};
      state.error = '';
      state.location = [];
    },
    getRideDetailSuccess: (state, action) => {
      state.ride = action.payload?.result;
      state.location = action.payload?.location;

      state.loading = false;
    },
    getRideDetailFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getRideDetailDefault: (state, action) => {
      state.loading = false;
      state.ride = {};
      state.error = '';
    },
  },
});

export const { getRideDetailRequest, getRideDetailSuccess, getRideDetailFailure, getRideDetailDefault } =
  getRideDetailSlice.actions;

export default getRideDetailSlice.reducer;
