import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  history: {},
  loading: false,
  error: '',
};

export const historyRideSlice = createSlice({
  name: 'ride/historyRide',
  initialState,
  reducers: {
    getHistoryRideRequest: (state) => {
      state.loading = true;
      state.history = {};
      state.error = '';
    },
    getHistoryRideSuccess: (state, action) => {
      state.history = action.payload;
      state.loading = false;
    },
    getHistoryRideFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getHistoryRideDefault: (state) => {
      state.loading = false;
      state.history = {};
      state.error = '';
    },
  },
});

export const { getHistoryRideSuccess, getHistoryRideRequest, getHistoryRideFailure, getHistoryRideDefault } =
  historyRideSlice.actions;

export default historyRideSlice.reducer;
