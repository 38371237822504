import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const paymentTransactionSlice = createSlice({
  name: 'financeManagement/paymentTransaction',
  initialState,
  reducers: {
    paymentTransactionRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.message = '';
      state.error = '';
    },
    paymentTransactionSuccess: (state, { payload }) => {
      state.success = true;
      state.message = payload;
      state.loading = false;
    },
    paymentTransactionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    paymentTransactionDefault: (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const {
  paymentTransactionDefault,
  paymentTransactionFailure,
  paymentTransactionRequest,
  paymentTransactionSuccess,
} = paymentTransactionSlice.actions;

export default paymentTransactionSlice.reducer;
