//type
export const TOAST_SUCCESS = 'success';
export const TOAST_WARNING = 'warning';
export const TOAST_DANGER = 'danger';

//action
export const TOAST_MSG_ADD = ' has been added successfully';
export const TOAST_MSG_ADD_BONUS = ' has been added bonus successfully';
export const TOAST_MSG_CHARGE_MONEY = ' has been charged money successfully';
export const TOAST_MSG_UPDATE = ' has been updated successfully';
export const TOAST_MSG_DELETE = ' has been deleted successfully';
export const TOAST_MSG_REFUND = ' has been refunded successfully';
export const TOAST_MSG_PAYMENT = ' has been payment successfully';
export const TOAST_MSG_END_RIDE = ' has been ended successfully';
export const TOAST_MSG_BLOCK = ' has been blocked successfully ';
export const TOAST_MSG_UNBLOCK = ' has been unblocked successfully ';
export const TOAST_MSG_SEND_NOTIFY = ' Successful notification sent ';
export const TOAST_MSG_UPLOAD_FILE = ' has been uploaded successfully';
export const TOAST_MSG_UPDATE_STATUS = 'Status updated to: ';
export const TOAST_MSG_SENT = ' has been sent successfully';

export const TOAST_MSG_ADD_S = 's have been added successfully';
export const TOAST_MSG_UPDATE_S = 's have been updated successfully';
export const TOAST_MSG_DELETE_S = 's have been deleted successfully';
export const TOAST_MSG_REFUND_S = 's have been refunded successfully';
export const TOAST_MSG_END_RIDE_S = 's have been ended successfully';
export const TOAST_MSG_BLOCK_S = 's have been blocked successfully ';
export const TOAST_MSG_UNBLOCK_S = 's have been unblocked successfully ';

export const TOAST_MSG_DOWNLOAD_S = ' Downloaded qr codes successfully ';
export const TOAST_MSG_SEND_NOTIFY_S = ' Successful notifications sent ';
export const TOAST_MSG_IMPORT = ' Imported data successfully ';
export const TOAST_MSG_CHANGE = ' Changes have been updated successfully';
export const TOAST_MSG_CHANGE_DETAIL = 'Status has been updated successfully';

// ride verification
export const TOAST_TITLE_RIDE_VERIFICATION = 'Vehicle';
export const TOAST_TITLE_COMMENT = 'Comment';

// ride
export const TOAST_TITLE_RIDE = 'Ride';

// Vehicles
export const TOAST_TITLE_VEHICLE = 'Vehicle';
export const TOAST_TITLE_VEHICLE_HISTORY = 'Vehicle history';
export const TOAST_TITLE_COMMAND_VEHICLE = 'Command';

// Customers
export const TOAST_TITLE_CUSTOMER = 'Customer';
export const TOAST_TITLE_NOTE = 'Note';

// Zone
export const TOAST_TITLE_ZONE = 'Zone';
export const TOAST_TITLE_PARKING_ZONE = 'Parking zone';
export const TOAST_TITLE_PAID_PARKING_ZONE = 'Paid parking zone';
export const TOAST_TITLE_BONUS_ZONE = 'Bonus zone';
export const TOAST_TITLE_NO_PARKING_ZONE = 'No parking zone';
export const TOAST_TITLE_SPEED_LIMIT_ZONE = 'Speed limit zone';
export const TOAST_TITLE_NO_GO_ZONE = 'No-go zone';
export const TOAST_TITLE_POLYGON = 'Polygon';

// Bundle
export const TOAST_TITLE_BUNDLE = 'Bundle';
export const TOAST_TITLE_BUNDLE_HISTORY = 'History bundle';

// Marketing
export const TOAST_TITLE_PROMOTION = 'Promo code';
export const TOAST_TITLE_CUSTOMER_GROUP = 'Group discount';
export const TOAST_TITLE_CUSTOMER_GROUP_TITLE = ' title';
export const TOAST_TITLE_CUSTOMER_GROUP_SUB = `. Customers from other groups can't be added, skipped customers: 0`;

// Setting
export const TOAST_TITLE_IOT = 'IoT';
export const TOAST_TITLE_RESERVATION_TIME = 'Reservation time';
export const TOAST_TITLE_RESERVATION = 'Reservation';
export const TOAST_TITLE_RESERVATION_DISTANCE = 'Reservation distance';
export const TOAST_TITLE_END_THE_RIDE = 'Automatically end the ride';
export const TOAST_TITLE_REBALANCING = 'Rebalancing';
export const TOAST_TITLE_RIDE_DISTANCE = 'Ride distance';
export const TOAST_TITLE_RATIO_PAYOUT = 'Ratio payout';

// Teams and accounts
export const TOAST_TITLE_TEAM_PERMISSION = 'User';
export const TOAST_TITLE_TEAM_SUBACCOUNT = 'Subaccount';

// Personalization
export const TOAST_TITLE_LOCALIZATION = 'Localization';

// Referral Bonus Amount
export const TOAST_TITLE_REFERRAL_BONUS_AMOUNT = 'Referral bonus amount';

// VAT
export const TOAST_TITLE_VAT = 'VAT';

// Payment
export const TOAST_TITLE_PAYMENT = 'Verification fee';

// Top-up
export const TOAST_TITLE_TOP_UP = 'Top-up';

// App banner message
export const TOAST_TITLE_APP_BANNER_MESSAGE = 'Message';

// Banner
export const TOAST_TITLE_MESSAGE = 'Message';

// Pricing
export const TOAST_TITLE_VERIFICATION_FEE = 'Verification fee';

// Legal
export const TOAST_TITLE_TERM_CONDITION = 'Terms and conditions';
export const TOAST_TITLE_POLICY = 'Privacy policy';

// Price For Ride
export const TOAST_TITLE_PRICE_FOR_RIDE = 'Price for ride';

// Price For Liability
export const TOAST_TITLE_PRICE_FOR_LIABILITY = 'Price for liability';

// Menu Advert
export const TOAST_TITLE_MENU_ADVERT = 'Rider Menu Advert';

//File pdf
export const TOAST_TITLE_UPLOAD_DOCUMENT = 'Document';

// Menu financial
export const TOAST_TITLE_FINANCIAL = 'Financial';
export const TOAST_TITLE_PAYMENT_TRANSACTION = 'Payment Transaction';

// Menu subAccountConfig
export const TOAST_TITLE_SUB_ACCOUNT_CONFIG = 'SubAccount Config';
