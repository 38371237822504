import { combineReducers } from '@reduxjs/toolkit';
import listAnalytic from './getListSlice';
import listHeatMap from './getHeatMap';

const reducer = combineReducers({
  listAnalytic,
  listHeatMap,
});

export default reducer;
