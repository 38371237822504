import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  promos: [],
  pagination: {},
  loading: false,
  error: '',
};

export const listPromoSlice = createSlice({
  name: 'marketing/listPromo',
  initialState,
  reducers: {
    getListPromoRequest: (state) => {
      state.loading = true;
    },
    getListPromoSuccess: (state, action) => {
      state.promos = action.payload?.responses;
      state.pagination = action.payload?.pagination;
      state.loading = false;
    },
    getListPromoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getListPromoSuccess, getListPromoRequest, getListPromoFailure } = listPromoSlice.actions;

export default listPromoSlice.reducer;
