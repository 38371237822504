import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  success: false,
  loading: false,
  error: '',
  message: '',
};

export const importIotSlice = createSlice({
  name: 'iot/importIot',
  initialState,
  reducers: {
    importIotRequest: (state) => {
      state.loading = true;
      state.success = false;
      state.error = '';
      state.message = '';
    },
    importIotSuccess: (state, action) => {
      state.success = true;
      state.message = action.payload;
      state.loading = false;
    },
    importIotFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetImportIot: (state, action) => {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
      state.message = initialState.message;
    },
  },
});

export const { importIotRequest, importIotSuccess, importIotFailure, resetImportIot } = importIotSlice.actions;

export default importIotSlice.reducer;
