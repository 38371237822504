import ApiService from './ApiService';

export async function apiGetAllVehicle(params) {
  return ApiService.fetchData({
    url: '/vehicles/list-vehicles',
    method: 'get',
    params,
  });
}

export async function apiCreateVehicle(data) {
  return ApiService.fetchData({
    url: '/vehicles/',
    method: 'post',
    data,
  });
}

export async function apiUpdateVehicle({ data, _id }) {
  return ApiService.fetchData({
    url: `/vehicles/${_id}`,
    method: 'put',
    data,
  });
}

export async function apiGetListVehicleType() {
  return ApiService.fetchData({
    url: '/settings/vehicle-type',
    method: 'get',
  });
}

export async function apiBulkMultipleVehicleType(data) {
  return ApiService.fetchData({
    url: '/vehicles/bulk/',
    method: 'post',
    data,
  });
}

export async function apiDeleteVehicleById(params) {
  return ApiService.fetchData({
    url: `/vehicles/${params?.id}`,
    method: 'delete',
  });
}

export async function apiGetVehicleById(params) {
  return ApiService.fetchData({
    url: `/vehicles/${params?.id}`,
    method: 'get',
  });
}

export async function apiGetVehicleActivity(params) {
  return ApiService.fetchData({
    url: `/vehicles/activity/${params?.id}`,
    method: 'get',
    params: {
      page: params?.page,
      limit: params?.limit,
    },
  });
}
export async function apiGetNotesForVehicleDetail({ id, params }) {
  return ApiService.fetchData({
    url: `/notes/${id}`,
    method: 'get',
    params,
  });
}

export async function apiCreateNoteForVehicleDetail(data) {
  return ApiService.fetchData({
    url: `/notes/`,
    method: 'post',
    data,
  });
}
export async function apiDownloadQRById(params) {
  return ApiService.fetchData({
    url: `/vehicles/generate-qr/${params?.id}`,
    method: 'get',
  });
}

export async function apiImportVehicle(data) {
  return ApiService.fetchData({
    url: `/vehicles/import`,
    method: 'post',
    data,
  });
}

export async function apiGetHistoryVehicle({ id, params }) {
  return ApiService.fetchData({
    url: `/vehicles/${id}/history`,
    method: 'get',
    params,
  });
}

export async function apiGetDocumentVehicle({ id, params }) {
  return ApiService.fetchData({
    // url: `/vehicles/${id}/history`,
    method: 'get',
    params,
  });
}

export async function apiUploadDocumentVehicle({ id, data }) {
  return ApiService.fetchData({
    url: `/vehicles/${id}/upload-document`,
    method: 'post',
    data,
  });
}

export async function apiCallCommandVehicle({ id, data }) {
  return ApiService.fetchData({
    url: `/vehicles/${id}/perform-command`,
    method: 'post',
    data,
  });
}

export async function apiDeleteDocument({ id }) {
  return ApiService.fetchData({
    url: `/vehicles/document/${id}`,
    method: 'delete',
  });
}
