import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  bundles: [],
  pagination: {},
  loading: false,
  error: '',
  success: false,
};

export const listBundleSlice = createSlice({
  name: 'bundle/listBundle',
  initialState,
  reducers: {
    getListBundleRequest: (state) => {
      state.loading = true;
      state.bundles = [];
      state.error = '';
      state.success = false;
    },
    getListBundleSuccess: (state, action) => {
      state.bundles = action.payload?.responses;
      state.pagination = action.payload?.pagination;
      state.success = true;
      state.loading = false;
    },
    getListBundleFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    getListBundleDefault: (state) => {
      state.loading = false;
      state.bundles = [];
      state.error = '';
      state.success = false;
    },
  },
});

export const { getListBundleSuccess, getListBundleRequest, getListBundleFailure, getListBundleDefault } =
  listBundleSlice.actions;

export default listBundleSlice.reducer;
